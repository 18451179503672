import { LosseLink, LossePlaatjie, losseContentParse } from '@ubo/losse-sjedel'
import { motion } from 'framer-motion'
import type { Maybe, Component_Header_MenuHeader_Submenu } from '~/graphql/types'

export default function HeaderSubmenu({
  fields,
  setCurrentIndex,
  setMobileMenuOpen
}: {
  fields: Maybe<Component_Header_MenuHeader_Submenu>
  setCurrentIndex: (index: number) => void
  setMobileMenuOpen: (open: boolean) => void
}) {
  const isMobile = window.innerWidth < 1280

  return (
    <motion.div
      className="fixed max-xl:px-6 max-xl:pt-6 max-xl:pb-20 max-xl:w-full max-xl:h-full top-0 left-0 xl:absolute z-10 xl:w-[1080px] 2xl:w-[1280px] xl:top-[calc(100%+theme(space.5))] xl:-left-[150px] 2xl:-left-[250px] bg-ftf-tundora xl:bg-white xl:box--rounded xl:box--shadow"
      initial={isMobile ? { opacity: 0, x: '100%' } : { opacity: 0, scale: 0.95, y: -20 }}
      animate={isMobile ? { opacity: 1, x: 0 } : { opacity: 1, scale: 1, y: 0 }}
      exit={isMobile ? { opacity: 0, x: '100%' } : { opacity: 0, scale: 0.95, y: -20 }}
      transition={isMobile ? { duration: 0.3 } : undefined}
    >
      <div
        className="absolute right-7 top-7 text-center xl:hidden"
        role="button"
        onClick={() => {
          setMobileMenuOpen(false)
        }}
        aira-label="Close menu"
      >
        <div className="relative ml-4 h-8 w-8 before:absolute before:h-[25px] before:w-[2px] before:rotate-45 before:bg-white before:[content:''] after:absolute after:h-[25px] after:w-[2px] after:-rotate-45 after:bg-white after:[content:'']" />
      </div>
      <div className="xl:hidden mt-1 h-[48px]">
        <button
          type="button"
          className="text-white flex items-center gap-2 text-lg font-black"
          onClick={() => {
            setCurrentIndex(-1)
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="rotate-90 w-6 h-6"
          >
            <polyline points="6 9 12 15 18 9" />
          </svg>
          Terug
        </button>
      </div>
      <div className="absolute -top-5 h-5 w-full z-10 max-xl:hidden" />
      <div className="w-full h-full overflow-y-scroll xl:max-h-[calc(100vh-150px)]">
        <div className="xl:p-20">
          {fields.title && <h2 className="mb-3 title lg:text-4xl text-ftf-mine-shaft max-xl:hidden">{fields.title}</h2>}
          {fields.subtitle && <h2 className="mb-10 sub--title text-ftf-punch max-xl:hidden">{fields.subtitle}</h2>}
          <div className="grid grid-cols-3 gap-10 lg:gap-10 xl:gap-28 max-xl:mt-16">
            <div className="lg:col-span-2 col-span-3">
              <div className="xl:pl-6 max-sm:pb-6">
                <ul className="grid sm:grid-cols-2 gap-x-3 sm:gap-x-10 xl:gap-x-16 gap-y-3 sm:gap-y-4 max-xl:px-2">
                  {fields.menu.map((item, index) => (
                    <LosseLink
                      key={`${item.link.url}-${index}`}
                      to={item.link.url}
                      target={item.link.target}
                      className="rounded-xl hover:bg-ftf-punch group p-2 lg:p-3 flex gap-1 relative"
                    >
                      {({ isActive }) => (
                        <>
                          {isActive && (
                            <div className="flex--center absolute -left-3 top-[22px]">
                              <div className="w-2 h-2 rounded-full bg-ftf-punch group-hover:bg-white mr-2" />
                            </div>
                          )}
                          <div>
                            <h4 className="text-ftf-mine-shaft sm:text-lg font-semibold group-hover:text-white xl:mb-4 max-xl:text-white">
                              {losseContentParse(item.link.title)}
                            </h4>
                            <p className="text-ftf-mine-shaft/50 text-sm font-medium group-hover:text-white max-xl:hidden">
                              {losseContentParse(item.subtitle)}
                            </p>
                          </div>
                        </>
                      )}
                    </LosseLink>
                  ))}
                </ul>
              </div>
            </div>
            <div className="lg:col-span-1 col-span-3 max-lg:hidden max-xl:pr-20">
              <LossePlaatjie src={fields.cta.image} className="rounded-2xl overflow-hidden object-cover" maxwidth={400} loading="eager" />
              <a
                href={`tel:${fields.cta.phonenumber}`}
                className="bg-ftf-mine-shaft rounded-2xl text-white px-4 xl:px-6 py-4 mx-6 -mt-12 relative z-10 block hover:bg-ftf-punch group"
              >
                <div className="block text-left text-lg font-medium mb-3">{fields.cta.description}</div>
                <div className="flex items-center font-medium">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2 text-ftf-punch group-hover:text-white"
                  >
                    <g clipPath="url(#clip0_1181_1623)">
                      <path
                        d="M14.7919 16.9385C11.7589 15.6544 9.34713 13.2369 8.06999 10.201L11.6117 6.65312L5.09083 0.127083L1.7877 3.42917C1.21479 4.00536 0.761815 4.68944 0.454994 5.44183C0.148174 6.19422 -0.00640439 6.99998 0.000203231 7.8125C0.000203231 15.3625 9.6377 25 17.1877 25C18.0001 25.0071 18.8059 24.8527 19.5582 24.5459C20.3105 24.239 20.9943 23.7858 21.57 23.2125L24.8731 19.9094L18.3471 13.3833L14.7919 16.9385ZM20.096 21.7396C19.7135 22.1188 19.2594 22.4181 18.7601 22.6202C18.2608 22.8223 17.7263 22.9231 17.1877 22.9167C10.695 22.9167 2.08354 14.3052 2.08354 7.8125C2.0774 7.27378 2.17829 6.73921 2.38035 6.23978C2.58241 5.74036 2.88162 5.28602 3.26062 4.90312L5.09083 3.07292L8.67104 6.65312L5.61374 9.71042L5.86895 10.35C6.62025 12.3597 7.79475 14.1844 9.31286 15.7006C10.831 17.2167 12.6572 18.3888 14.6679 19.1375L15.2992 19.3781L18.3471 16.3292L21.9273 19.9094L20.096 21.7396ZM14.5835 2.08333V0C17.3453 0.00303291 19.993 1.10147 21.9459 3.05432C23.8987 5.00717 24.9972 7.65493 25.0002 10.4167H22.9169C22.9144 8.20729 22.0356 6.08912 20.4734 4.52685C18.9111 2.96458 16.7929 2.08581 14.5835 2.08333ZM14.5835 6.25V4.16667C16.2406 4.16832 17.8294 4.82733 19.0011 5.99908C20.1729 7.17082 20.8319 8.75957 20.8335 10.4167H18.7502C18.7502 9.3116 18.3112 8.25179 17.5298 7.47039C16.7484 6.68899 15.6886 6.25 14.5835 6.25Z"
                        fill="currentColor"
                      />
                    </g>
                  </svg>

                  {fields.cta.phonenumber}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
