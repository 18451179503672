import ContentFields from '~/components/elements/ContentFields'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import CylinderTilted from '~/shapes/CylinderTilted'

export default function ContentWithBgForm({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentWithBgForm" className="section overflow-hidden bg-white">
      <div className="container relative">
        <div className="lg:grid grid-cols-12 gap-20">
          <div className="col-span-7 relative z-10">
            <ContentFields fields={fields} type="alternative" />
          </div>
          <div className="col-span-5 sm:relative">
            <CylinderTilted className="absolute text-ftf-punch max-lg:opacity-25 w-[250px] h-[250px] sm:w-[350px] sm:h-[350px] xl:w-[450px] xl:h-[450px] max-sm:top-0 sm:-bottom-10 lg:-bottom-20 max-sm:-right-[150px] right-0" />
          </div>
        </div>
      </div>
    </section>
  )
}
