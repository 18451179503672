import { useMatches } from '@ubo/losse-sjedel'
import type { FormDuufProps } from '@ubo/form-duuf'
import FormDuuf, { FormDuufGroup, useFormDuuf } from '@ubo/form-duuf'
import Loading from './Loading'
import Content from './Content'

type Props = Omit<FormDuufProps, 'renderStatus'>

export default function Form(props: Props) {
  const matches = useMatches() as any
  const formId = props.data.formId
  const apiUrl = `${matches[0].data.config.wordpressUrl}/wp-json/gf/v2/forms/${formId}`

  return (
    <FormDuuf
      apiUrl={apiUrl}
      renderLoading={() => (
        <div className="absolute -left-4 -top-4 w-[calc(100%+theme(space.8))] h-[calc(100%+theme(space.8))] bg-white flex--center z-10">
          <div className="flex flex-col items-center">
            <Loading className="text-ftf-punch" />
            <span className="mt-2 font-medium text-sm">Aan het versturen ...</span>
          </div>
        </div>
      )}
      privacyUrl="/privacyverklaring/"
      privacyUrlPlacement="privacyverklaring"
      renderStatus={({ content }) => (
        <>
          <div className="flex--center">
            <div className="bg-ftf-la-palma flex--center w-24 h-24 rounded-full box--shadow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="text-white w-[60%] h-[60%]"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
          </div>
          <Content className="content mt-4 text-center">{content}</Content>
        </>
      )}
      {...props}
      className="relative"
      generate={false}
    >
      <>
        <FormDuufGroup />
        <FormSubmit />
      </>
    </FormDuuf>
  )
}

function FormSubmit() {
  const context = useFormDuuf()
  const isValid: boolean =
    context.captchaFilled &&
    context.formik.isSubmitting === false &&
    Object.keys(context.formik.touched).length > 0 &&
    Object.keys(context.formik.errors).length === 0

  return (
    <div className="form-duuf-group form-duuf-group-submit pt-2">
      <span className={`form-duuf-submit${!isValid ? ' form-duuf-submit-disabled' : ''}`}>
        <button type="submit" disabled={!isValid}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5">
            <g id="01 align center">
              <path
                id="Vector"
                d="M1.4437 6.66874C1.10885 6.76442 0.804795 6.94586 0.561607 7.19513C0.318419 7.44439 0.144531 7.75284 0.0571494 8.08994C-0.0302325 8.42704 -0.0280796 8.78112 0.063395 9.11713C0.15487 9.45315 0.332495 9.75945 0.578696 10.0057L3.9907 13.4137V19.9997H10.5837L14.0187 23.4297C14.2031 23.6157 14.4224 23.7634 14.664 23.8643C14.9056 23.9652 15.1648 24.0173 15.4267 24.0177C15.5988 24.0174 15.7702 23.9952 15.9367 23.9517C16.2737 23.8669 16.5823 23.6947 16.8316 23.4526C17.0809 23.2105 17.262 22.9071 17.3567 22.5727L23.9907 0.020752L1.4437 6.66874ZM1.9997 8.59174L19.0277 3.57175L5.9927 16.5857V12.5857L1.9997 8.59174ZM15.4397 22.0157L11.4127 17.9997H7.4127L20.4457 4.97775L15.4397 22.0157Z"
                fill="currentColor"
              />
            </g>
          </svg>
          {context.form.button.text}
        </button>
      </span>
    </div>
  )
}
