/* eslint-disable react-hooks/exhaustive-deps */
import type { Page_Flexcontent_Flex_Banner, Pakket } from '~/graphql/types'
import Typewriter from 'typewriter-effect'
import debounce from 'lodash.debounce'
import { useCallback, useRef, useState } from 'react'
import clsx from 'clsx'
import { LosseLink, LossePlaatjie, losseLoader, useFetcher } from '@ubo/losse-sjedel'
import { useAppContext } from '../layout/Layout'
import Loading from './Loading'
import Content from './Content'

export default function SearchAccounting({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const inputRef = useRef<HTMLInputElement>(null)
  const appContext = useAppContext()
  const [isTyping, setIsTyping] = useState(false)
  const [value, setValue] = useState('')
  const [showMore, setShowMore] = useState(false)
  const fetcher = useFetcher<{ nodes: Pakket[] }>({
    key: 'SearchPakket'
  })
  const setDebounce = useCallback(debounce(handleDebounce, 400), [])

  function handleDebounce(inputValue: string) {
    fetcher.load(losseLoader({ name: 'SearchPakket', variables: { search: inputValue } }))
  }

  function husslePlaceholders() {
    const placeholders = fields?.searchPlaceholders
    const placeholdersArray = placeholders?.split(',')
    const shuffled = placeholdersArray?.sort(() => 0.5 - Math.random())
    const randomPlaceholders = shuffled?.slice(0, 3)

    return randomPlaceholders
  }

  return (
    <div>
      <h3 className="sub--title text-white lg:!text-xl">{fields.searchTitle}</h3>
      <div className="mt-6 lg:pr-16">
        <div className="bg-white rounded-full flex items-center justify-between h-10 lg:h-11">
          <div
            role="button"
            onClick={() => {
              setIsTyping(true)

              setTimeout(() => {
                inputRef.current.focus()
              }, 250)
            }}
            className={clsx(isTyping && 'hidden', 'pl-6 cursor-text flex items-center lg:text-lg h-full w-full font-ftf-visby text-black')}
          >
            <Typewriter
              options={{
                strings: husslePlaceholders(),
                autoStart: true,
                loop: true
              }}
            />
          </div>
          <input
            ref={inputRef}
            type="text"
            className={clsx(!isTyping && 'hidden', 'w-full bg-transparent h-full pl-6 lg:text-lg')}
            value={value}
            onChange={(event) => {
              setValue(event.target.value)
              setDebounce(event.target.value)
            }}
            placeholder="Zoek hier naar een app"
          />
          <div className="bg-ftf-punch px-6 lg:px-10 text-white rounded-full h-full flex--center">
            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-6 h-6">
              <path
                d="M26.9996 25.4101L19.9549 18.3654C21.7848 16.1273 22.6846 13.2715 22.468 10.3886C22.2514 7.50576 20.935 4.81645 18.7912 2.87694C16.6473 0.937428 13.84 -0.103893 10.95 -0.0316321C8.05991 0.0406287 5.30817 1.22094 3.26395 3.26517C1.21972 5.30939 0.039408 8.06113 -0.0328528 10.9512C-0.105114 13.8413 0.936207 16.6486 2.87572 18.7924C4.81523 20.9362 7.50454 22.2526 10.3874 22.4692C13.2702 22.6858 16.1261 21.7861 18.3641 19.9561L25.4089 27.0009L26.9996 25.4101ZM11.2496 20.2509C9.4696 20.2509 7.72954 19.723 6.2495 18.7341C4.76946 17.7451 3.61591 16.3395 2.93472 14.695C2.25353 13.0505 2.0753 11.2409 2.42256 9.49504C2.76983 7.74921 3.627 6.14556 4.88567 4.88689C6.14434 3.62822 7.74799 2.77105 9.49382 2.42379C11.2396 2.07652 13.0492 2.25475 14.6938 2.93594C16.3383 3.61713 17.7439 4.77068 18.7329 6.25072C19.7218 7.73076 20.2496 9.47082 20.2496 11.2509C20.247 13.637 19.2979 15.9246 17.6106 17.6119C15.9234 19.2991 13.6358 20.2482 11.2496 20.2509Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
      </div>
      {fetcher.state === 'loading' && (
        <div className="p-10 flex--center">
          <Loading className="text-ftf-punch" size="small" />
        </div>
      )}
      {fetcher?.data && fetcher.state === 'idle' && (
        <div className="mt-6 lg:mt-8">
          <div className="h-[1px] w-full bg-white/25"></div>

          <div className="mt-6 lg:mt-8 grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {fetcher.data?.nodes
              ?.filter((_, index) => showMore || index < 4)
              ?.map((pakket) => (
                <button
                  type="button"
                  key={pakket?.title}
                  className="bg-white rounded-xl pt-4 pb-2 flex flex-col items-center justify-between aspect-square hover:bg-ftf-seashell group transition-colors"
                  onClick={() => {
                    appContext.setPopupSubject(`Ik wil "${pakket?.title}" koppelen met mijn boekhoudpakket`)
                    window.location.hash = '#adviesgesprek'
                  }}
                  title={pakket.title}
                >
                  <div className="px-4">
                    <LossePlaatjie
                      src={pakket?.recap?.logo}
                      alt={pakket?.title}
                      className="w-full max-h-[100%%] object-contain"
                      maxwidth={300}
                    />
                  </div>
                  <div className="text-xs font-bold w-full px-2 group-hover:underline">{pakket.title}</div>
                </button>
              ))}
            {fetcher.data?.nodes.length > 4 && (
              <div className="col-span-full flex--center mt-2">
                <button
                  type="button"
                  onClick={() => {
                    setShowMore(!showMore)
                  }}
                  className="font-bold text-sm text-ftf-seashell underline"
                >
                  {showMore ? 'Minder tonen' : 'Meer tonen'}
                </button>
              </div>
            )}
            {fetcher.data?.nodes.length === 0 && (
              <div className="col-span-full flex--center">
                <Content className="text-white content lg:text-center xl:px-20">
                  Zit jouw app er niet bij? Neem dan <LosseLink to="/contact/">contact</LosseLink> op om de mogelijkheden te bespreken.
                </Content>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
