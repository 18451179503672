import { type BreadcrumbProps, LosseKruumels, useLoaderData, useNavigate } from '@ubo/losse-sjedel'

const POST_TYPE_CRUMB: { [key: string]: BreadcrumbProps } = {
  oplossing: { title: 'Oplossingen', uri: '/oplossingen/' },
  dienst: { title: 'Diensten', uri: '/diensten/' },
  post: { title: 'Blogs', uri: '/blogs/' },
  case: { title: 'Cases', uri: '/cases/' },
  branche: { title: 'Branches', uri: '/branches/' }
}

export default function Breadcrumbs() {
  const { page } = useLoaderData()
  const navigate = useNavigate()
  const contentType = page?.contentType?.node?.name
  const crumbs: BreadcrumbProps[] = [{ title: 'Home', uri: '/' }]

  if (contentType && contentType in POST_TYPE_CRUMB) crumbs.push(POST_TYPE_CRUMB[contentType])

  return (
    <div className="flex items-center text-xs xs:text-sm">
      <button
        type="button"
        onClick={() => {
          navigate(-1)
        }}
        className="flex items-center gap-2 text-white hover:underline max-sm:hidden"
      >
        <svg width="4" height="7" viewBox="0 0 4 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.93927 6.70697L0.292785 4.06048C0.105315 3.87295 0 3.61865 0 3.35348C0 3.08832 0.105315 2.83401 0.292785 2.64649L2.93927 0L3.64627 0.706996L1.00178 3.35348L3.64827 5.99997L2.93927 6.70697Z"
            fill="currentColor"
          />
        </svg>
        Terug
      </button>
      <span className="mx-4 text-white max-sm:hidden">|</span>
      <LosseKruumels
        type="fragment"
        className="children-ol:flex children-li:flex children-a:children-li:opacity-50 children-span:children-li:opacity-50 children-ol:items-start text-white children-a:last:children-li:opacity-100 last:children-li:font-semibold hover:children-a:underline children-a:first-letter:uppercase children-a:inline-block"
        preCrumbs={crumbs}
        seperator={
          <span className="mx-1 sm:mx-3 flex items-start mt-[3px] sm:mt-[5px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="h-3 w-3"
            >
              <polyline points="9 18 15 12 9 6" />
            </svg>
          </span>
        }
      />
    </div>
  )
}
