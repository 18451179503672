import { LossePlaatjie } from '@ubo/losse-sjedel'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import DetailBanner from '~/components/elements/DetailBanner'
import type { Page_Flexcontent_Flex_Detail } from '~/graphql/types'

export default function DetailPostBanner({ fields }: { fields: Page_Flexcontent_Flex_Detail }) {
  return (
    <section data-component="DetailPostBanner" className="section--bottom">
      <div className="container 2xl:max-w-screen-2xl relative z-10 sm:section--detail--bottom space--top">
        <Breadcrumbs />
        <div className="grid sm:grid-cols-2 gap-6 sm:gap-10 lg:gap-20 mt-10 max-w-screen-xl mx-auto">
          <div className="sm:py-6 text-white flex items-center order-2">
            <div className="w-full">
              <DetailBanner fields={fields} />
            </div>
          </div>
          <div className="flex items-center justify-center sm:order-3 order-1">
            <LossePlaatjie src={fields?.image} alt={fields?.title} className="detail--image" loading="eager" maxwidth={700} />
          </div>
        </div>
      </div>
    </section>
  )
}
