import { Fragment, useState } from 'react'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import { motion } from 'framer-motion'
import Logo from '~/shapes/Logo'

export default function ContentIdAccordion({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [currentIndex, setCurrentIndex] = useState<number | string>(0)

  return (
    <section data-component="ContentIdAccordion" className="max-sm:overflow-hidden relative z-30">
      <div className="bg-white section">
        <div className="container">
          <div className="grid sm:grid-cols-12 sm:gap-8 md:gap-10 lg:gap-20">
            <div className="sm:col-span-5">
              {/* 0,607142857142857 aspect ratio */}
              <Logo className="absolute sm:relative max-sm:-right-6 max-sm:mt-10 max-sm:opacity-10 sm:-mt-[150px] lg:-mt-[200px] w-[212px] h-[350px] lg:h-[550px] lg:w-[334px] xl:w-[425px] xl:h-[700px]" />
            </div>
            <div className="sm:col-span-7 flex flex-col gap-4 md:gap-16 xl:gap-20">
              {fields?.blocks?.map((block, index) => {
                const titles = block?.title?.split(' ')

                return (
                  <div key={index} className="text-ftf-mine-shaft sm:-m-4">
                    <button
                      type="button"
                      onClick={() => {
                        setCurrentIndex(index === currentIndex ? -1 : index)
                      }}
                      className="flex items-start justify-between w-full group hover:bg-ftf-seashell p-4 rounded-2xl"
                    >
                      <h2 className="text-2xl md:text-3xl lg:text-5xl xl:text-6xl children-strong:font-black font-light text-left text-ftf-mine-shaft">
                        {titles.map((title, _index) => (
                          <Fragment key={_index}>
                            {_index > 0 && <br />}
                            {_index === 0 ? <strong>{title}</strong> : title}
                          </Fragment>
                        ))}
                      </h2>
                      <div className="mt-5">
                        <motion.svg
                          animate={index === currentIndex ? 'open' : 'closed'}
                          variants={{
                            open: { rotate: 0 },
                            closed: { rotate: -90 }
                          }}
                          width="45"
                          height="23"
                          viewBox="0 0 45 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-[20px] h-[10px] md:w-[35px] md:h-[18px] xl:w-[45px] xl:h-[23px]"
                        >
                          <g id="01 align center">
                            <path
                              id="Vector"
                              d="M42.3492 0.116943L23.8261 18.6401C23.4696 18.9822 22.9946 19.1733 22.5005 19.1733C22.0063 19.1733 21.5313 18.9822 21.1748 18.6401L2.66297 0.126319L0.0117188 2.77757L18.5236 21.2913C19.5958 22.3132 21.0202 22.8833 22.5014 22.8833C23.9826 22.8833 25.407 22.3132 26.4792 21.2913L45.0005 2.76819L42.3492 0.116943Z"
                              fill="currentColor"
                            />
                          </g>
                        </motion.svg>
                      </div>
                    </button>
                    <motion.div
                      animate={index === currentIndex ? 'open' : 'closed'}
                      variants={{
                        open: {
                          height: 'auto',
                          opacity: 1,
                          scale: 1
                        },
                        closed: {
                          height: 0,
                          opacity: 0,
                          scale: 0.9
                        }
                      }}
                      className="overflow-hidden"
                    >
                      <Content className="content px-4 children-p:leading-6 mt-2 lg:mt-10">{block?.description}</Content>
                    </motion.div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
