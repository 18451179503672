import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { ContentFieldsContent, ContentFieldsLinks, ContentFieldsSubtitle, ContentFieldsTitle } from '~/components/elements/ContentFields'
import Flow from '~/components/elements/Flow'
import CylinderAndCircle from '~/shapes/CylinderAndCircle'
import SearchAccounting from '~/components/elements/SearchAccounting'

export default function BannerLandingFlowAndSearch({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerLandingFlowAndSearch" className="overflow-hidden section--bottom">
      <div className="container section--detail--top sm:section--bottom relative">
        <div className="grid sm:grid-cols-2 gap-10 lg:gap-20 relative z-10">
          <div className="lg:pt-10 xl:pt-16">
            <div className="text-white">
              <ContentFieldsTitle fields={fields} type="banner" />
              <ContentFieldsSubtitle fields={fields} type="banner" />
              <div className="sm:hidden flex space">
                <TabletFlow fields={fields} />
              </div>
              <div className="max-sm:children-p:text-base max-sm:px-4 max-sm:children-p:leading-8">
                <ContentFieldsContent fields={fields} type="banner" />
              </div>
              <ContentFieldsLinks fields={fields} type="banner" />
            </div>

            <div className="mt-10 lg:mt-16 xl:mt-20 relative bg-ftf-dove-gray rounded-r-2xl pr-6 py-6 lg:pr-20 lg:py-10 after:contens after:absolute after:right-full after:top-0 after:bottom-0 after:bg-ftf-dove-gray after:w-[2000px]">
              <SearchAccounting fields={fields} />
            </div>
          </div>
          <div className="flex justify-end max-sm:hidden">
            <TabletFlow fields={fields} />
          </div>
        </div>
        {/* 0,565829145728643 aspect ratio */}
        <CylinderAndCircle className="w-[283px] h-[500px] lg:w-[396px] lg:h-[700px] xl:w-[509px] xl:h-[900px] absolute z-0 -right-[50px] sm:-right-[150px] bottom-4 sm:top-14 lg:top-[25px] max-sm:hidden" />
      </div>
    </section>
  )
}

function TabletFlow({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <div className="relative max-sm:mx-auto max-sm:max-w-[90%]">
      {/* 1,38785046728972 aspect ratio */}
      <img
        src="/static/ipad.png"
        width="642"
        height="891"
        alt="FTF flow"
        className="h-[472px] w-[340px] lg:h-[611px] lg:w-[440px] xl:h-[763px] xl:w-[600px] max-sm:mx-auto"
      />
      <div className="absolute left-0 top-0 w-full h-full p-6 sm:p-10 lg:pt-16 lg:px-16 lg:pb-[30px]">
        <Flow key={JSON.stringify(fields?.flow)} fields={fields} itemClassName="max-w-[145px] lg:max-w-[200px] xl:max-w-[250px]" />
      </div>
    </div>
  )
}
