import clsx from 'clsx'
import Content from './Content'
import type {
  Page_Flexcontent_Flex_Banner,
  Page_Flexcontent_Flex_Content,
  Page_Flexcontent_Flex_Detail,
  Page_Flexcontent_Flex_Form,
  Page_Flexcontent_Flex_Posts
} from '~/graphql/types'
import { LosseLink, losseContentParse } from '@ubo/losse-sjedel'
import { createElement } from 'react'

type Type = 'normal' | 'light' | 'banner' | 'alternative' | 'notable' | 'banner-notable'
type Button = 'normal' | 'alternative'
type Fields =
  | Page_Flexcontent_Flex_Content
  | Page_Flexcontent_Flex_Posts
  | Page_Flexcontent_Flex_Banner
  | Page_Flexcontent_Flex_Form
  | Page_Flexcontent_Flex_Detail

export default function ContentFields({ fields, type = 'normal', buttons = 'normal' }: { fields: Fields; type?: Type; buttons?: Button }) {
  return (
    <>
      {type === 'alternative' && fields?.subtitle && (
        <div className="text-base lg:text-lg uppercase text-ftf-punch mb-4 tracking-widest">{fields?.subtitle}</div>
      )}
      <ContentFieldsTitle fields={fields} type={type} />
      {type !== 'alternative' && <ContentFieldsSubtitle fields={fields} type={type} />}
      <ContentFieldsContent fields={fields} type={type} />
      <ContentFieldsLinks fields={fields} type={type} buttons={buttons} />
    </>
  )
}

export function ContentFieldsTitle({ fields, type = 'normal' }: { fields: Fields; type?: Type }) {
  if (!fields?.title) return null

  return createElement(
    type === 'banner' || type === 'banner-notable' ? 'h1' : 'h2',
    {
      className: clsx(
        'title mb-4',
        (type === 'notable' || type === 'banner-notable') && 'max-sm:text-4xl xl:text-7xl xl:leading-[5rem]',
        type !== 'notable' && type !== 'banner-notable' && 'xl:text-5xl xl:leading-[4rem]'
      )
    },
    losseContentParse(fields?.title.replace(/\|/g, '<br />'))
  )
}

export function ContentFieldsSubtitle({ fields, type = 'normal' }: { fields: Fields; type?: Type }) {
  if (!fields?.subtitle) return null

  return createElement(
    type === 'banner' || type === 'banner-notable' ? 'h2' : 'h3',
    { className: 'sub--title text-ftf-punch mb-8 xl:text-2xl' },
    fields?.subtitle
  )
}

export function ContentFieldsContent({ fields, type = 'normal' }: { fields: Fields; type?: Type }) {
  if (!fields.description) return null

  return (
    <Content
      className={clsx(
        'content pt-2 lg:pt-4',
        type === 'normal' && 'max-w-sm children-p:font-semibold',
        (type === 'light' || type === 'banner' || type === 'banner-notable') && 'max-w-lg children-p:font-light',
        type === 'notable' && 'max-w-sm sm:children-p:font-bold max-sm:children-p:text-base max-sm:children-p:leading-8 lg:text-lg'
      )}
    >
      {fields?.description}
    </Content>
  )
}

export function ContentFieldsLinks({
  fields: _fields,
  type = 'normal',
  buttons = 'normal',
  className
}: {
  fields: Fields
  type?: Type
  buttons?: Button
  className?: string
}) {
  const fields = _fields as Page_Flexcontent_Flex_Content

  if (!fields?.links) return null

  const isBanner = type === 'banner' || type === 'banner-notable'

  return (
    <div className={clsx('sm:flex items-center gap-3 lg:gap-4 mt-8 sm:mt-8', className)}>
      {fields.links.map((link, index) => (
        <div key={index} className="flex items-center gap-4">
          <LosseLink
            className={clsx(
              buttons === 'normal' && index === 0 && 'btn--punch',
              buttons === 'normal' && index > 0 && 'btn--la-palma',
              buttons === 'alternative' && index === 0 && 'btn--mine-shaft',
              buttons === 'alternative' && index > 0 && 'btn--la-palma',
              'flex items-center'
            )}
            to={link?.link?.url}
            target={link?.link?.target}
          >
            {buttons === 'normal' && index === 0 && !isBanner && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-4 lg:h-6 text-ftf-mine-shaft"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            )}
            <span className={clsx(index === 0 && !isBanner && 'sm:pl-2', 'w-full text-center')}>{link?.link?.title}</span>
          </LosseLink>
          {link?.label && <span className="text-xs font-medium">{link?.label}</span>}
        </div>
      ))}
    </div>
  )
}
