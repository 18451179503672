import { LossePlaatjie } from '@ubo/losse-sjedel'
import { useState } from 'react'
import Content from '~/components/elements/Content'
import ContentFields from '~/components/elements/ContentFields'
import type { Page_Flexcontent_Flex_Content, Page_Flexcontent_Flex_Content_Blocks } from '~/graphql/types'
import { motion } from 'framer-motion'
import clsx from 'clsx'

export default function ContentAccordion({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [currentIndex, setCurrentIndex] = useState<number | string>('1-0')

  return (
    <section data-component="ContentAccordion" className="section bg-gradient-to-b from-ftf-mine-shaft to-ftf-dove-gray">
      <div className="container lg:max-w-[1160px]">
        <div className="text-white text-center">
          <ContentFields fields={fields} />
        </div>

        <div className="grid sm:grid-cols-3 gap-6 lg:gap-12 space--top">
          <div className="flex flex-col gap-6 lg:gap-12">
            {fields?.blocks
              ?.filter((_, index) => (index + 1) % 3 === 0)
              .map((block, index) => (
                <Bellow key={index} block={block} index={`1-${index}`} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
              ))}
          </div>
          <div className="flex flex-col gap-6 lg:gap-12">
            {fields?.blocks
              ?.filter((_, index) => (index + 2) % 3 === 0)
              .map((block, index) => (
                <Bellow key={index} block={block} index={`2-${index}`} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
              ))}
          </div>
          <div className="flex flex-col gap-6 lg:gap-12">
            {fields?.blocks
              ?.filter((_, index) => (index + 0) % 3 === 0)
              .map((block, index) => (
                <Bellow key={index} block={block} index={`3-${index}`} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
              ))}
          </div>
        </div>
      </div>
    </section>
  )
}

function Bellow({
  block,
  index,
  currentIndex,
  setCurrentIndex
}: {
  block: Page_Flexcontent_Flex_Content_Blocks
  index: string
  currentIndex: number | string
  setCurrentIndex: (index: number | string) => void
}) {
  return (
    <div
      className={clsx(
        'bg-ftf-mine-shaft rounded-2xl px-4 lg:px-6 relative',
        index === currentIndex ? 'pb-14 lg:pb-16' : 'pb-14 sm:pb-10 lg:pb-12'
      )}
    >
      <button
        type="button"
        className="flex items-start justify-between w-full group pt-4 lg:pt-8"
        onClick={() => {
          setCurrentIndex(index === currentIndex ? -1 : index)
        }}
      >
        <h4 className="text-white text-left max-lg:break-words lg:text-lg font-semibold mb-4 leading-6 pr-4 lg:pr-10 sm:h-[4.5rem] lg:h-14 line-clamp-2 sm:line-clamp-3 lg:line-clamp-2 group-hover:underline">
          {block?.title}
        </h4>
        <div className="relative text-white/25 group-hover:text-white transition-colors">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1261_9437)">
              <path d="M24 10.9997H0V12.9997H24V10.9997Z" fill="currentColor" />
            </g>
          </svg>
          <motion.svg
            animate={index === currentIndex ? 'open' : 'closed'}
            variants={{
              open: { rotate: 0 },
              closed: { rotate: 90 }
            }}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute top-0 left-0"
          >
            <g clipPath="url(#clip0_1261_9437)">
              <path d="M24 10.9997H0V12.9997H24V10.9997Z" fill="currentColor" />
            </g>
          </motion.svg>
        </div>
      </button>
      <motion.div
        animate={index === currentIndex ? 'open' : 'closed'}
        variants={{
          open: {
            height: 'auto',
            opacity: 1,
            scale: 1
          },
          closed: {
            height: 0,
            opacity: 0,
            scale: 0.9
          }
        }}
        className="overflow-hidden"
      >
        <Content className="content text-sm text-white/75 children-p:leading-6 max-sm:pr-10">{block?.description}</Content>
      </motion.div>
      <LossePlaatjie
        src={block?.icon}
        className="absolute right-4 bottom-4 object-contain w-[50px] h-[50px] sm:w-[60px] sm:h-[60px] lg:w-[65px] lg:h-[65px] opacity-10"
        alt={block?.title}
        maxwidth={150}
      />
    </div>
  )
}
