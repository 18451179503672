import clsx from 'clsx'

export default function Hyperjump({ className = undefined }: { className?: string }) {
  return (
    <svg
      width="1728"
      height="1226"
      viewBox="0 0 1728 1226"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('pointer-events-none', className)}
    >
      <g opacity="0.25">
        <path d="M717.66 612.372L-112 612.372" stroke="#E0E0E0" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M2051 612.29H893.578" stroke="#E0E0E0" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1477.1 706.231L13.9199 847.762" stroke="#E0E0E0" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M13.9199 1078.59L2051 692.934" stroke="#E0E0E0" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M966.014 1038.37L322.916 1221.98" stroke="#E0E0E0" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1721.36 822.708L1056.5 1012.53" stroke="#E0E0E0" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M883.572 1221.98L2051 764.254" stroke="#E0E0E0" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M2051 807.419L1225.91 1221.98" stroke="#E0E0E0" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M2051 575.262L13.9199 378.205" stroke="#E0E0E0" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M13.9199 147.372L1257.8 382.867" stroke="#E0E0E0" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1079.16 219.91L322.916 4" stroke="#E0E0E0" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M1666.4 387.572L1155.15 241.605" stroke="#E0E0E0" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M883.572 4L2051 461.725" stroke="#E0E0E0" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M2051 418.56L1225.91 4" stroke="#E0E0E0" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" />
      </g>
    </svg>
  )
}
