import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import BannerDefault from './BannerDefault'
import BannerLandingFlowAndSearch from './BannerLandingFlowAndSearch'
import BannerWithFlow from './BannerWithFlow'
import BannerWithoutMedia from './BannerWithoutMedia'

export default function BannerShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: BannerDefault,
    landing_with_flow_and_search: BannerLandingFlowAndSearch,
    with_flow: BannerWithFlow,
    without_media: BannerWithoutMedia
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
