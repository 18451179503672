import { useLoaderData } from '@ubo/losse-sjedel'

export default function Share() {
  const data = useLoaderData()

  return (
    <div className="flex items-center gap-2">
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${data?.request?.url}`}
        target="_blank"
        rel="noreferrer"
        className="hover:text-white"
      >
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 lg:w-6 lg:h-6">
          <path
            d="M3.61084 1.26953C1.89222 1.26953 0.768555 2.39806 0.768555 3.88136C0.768555 5.3319 1.85875 6.49261 3.54489 6.49261H3.57751C5.32974 6.49261 6.42022 5.3319 6.42022 3.88136C6.38746 2.39806 5.32974 1.26953 3.61084 1.26953Z"
            fill="currentColor"
          />
          <path d="M1.06641 8.5564H6.09065V23.672H1.06641V8.5564Z" fill="currentColor" />
          <path
            d="M18.4224 8.20166C15.712 8.20166 13.8945 10.7485 13.8945 10.7485V8.55643H8.87012V23.6721H13.8942V15.2309C13.8942 14.779 13.927 14.3278 14.0597 14.0047C14.4229 13.1023 15.2495 12.1675 16.6375 12.1675C18.4555 12.1675 19.1827 13.5536 19.1827 15.5857V23.6721H24.2065V15.005C24.2065 10.3622 21.7277 8.20166 18.4224 8.20166Z"
            fill="currentColor"
          />
        </svg>
      </a>
      <a
        href={`https://www.linkedin.com/shareArticle?url=${data?.request?.url}&title=${
          data?.page?.title
        }&summary=${data?.page?.recap?.excerpt?.replace(/<[^>]+>/g, '')}`}
        target="_blank"
        rel="noreferrer"
        className="hover:text-white"
      >
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 lg:w-6 lg:h-6">
          <path
            d="M13.2811 23V12.5094H16.801L17.3291 8.4198H13.2811V5.80919C13.2811 4.62553 13.6085 3.81888 15.3078 3.81888L17.4715 3.81799V0.160114C17.0973 0.111487 15.8129 0 14.3179 0C11.1961 0 9.05884 1.90551 9.05884 5.40416V8.4198H5.52832V12.5094H9.05884V23H13.2811Z"
            fill="currentColor"
          />
        </svg>
      </a>
      <a
        href={`whatsapp://send?text=${data?.page?.title}%20-%20${data?.request?.url}`}
        data-action="share/whatsapp/share"
        target="_blank"
        rel="noreferrer"
        className="hover:text-white"
      >
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 lg:w-6 lg:h-6">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.1277 3.84108C17.1104 1.82144 14.4276 0.708704 11.5695 0.70752C5.68004 0.70752 0.886915 5.50052 0.884546 11.3915C0.883757 13.2747 1.3757 15.113 2.31077 16.7333L0.794922 22.27L6.45916 20.7842C8.01989 21.6355 9.77697 22.0842 11.5651 22.0847H11.5696C17.4583 22.0847 22.252 17.2913 22.2542 11.4001C22.2554 8.54498 21.1451 5.86059 19.1277 3.84108ZM11.5695 20.2803H11.5658C9.97228 20.2796 8.40944 19.8513 7.04573 19.0424L6.72158 18.8498L3.36034 19.7316L4.2575 16.4544L4.04627 16.1184C3.15727 14.7045 2.68783 13.0702 2.68862 11.3922C2.69046 6.49573 6.67447 2.51212 11.573 2.51212C13.9451 2.51291 16.1749 3.43784 17.8516 5.11649C19.5283 6.79514 20.4511 9.0264 20.4503 11.3994C20.4482 16.2962 16.4645 20.2803 11.5695 20.2803ZM16.4408 13.629C16.1739 13.4953 14.8612 12.8496 14.6164 12.7604C14.3719 12.6713 14.1937 12.6269 14.0159 12.8941C13.8378 13.1612 13.3263 13.7627 13.1705 13.9407C13.0146 14.1189 12.8591 14.1413 12.592 14.0076C12.325 13.874 11.4648 13.592 10.445 12.6824C9.65142 11.9745 9.11565 11.1003 8.95982 10.8331C8.80426 10.5657 8.95851 10.4351 9.07695 10.2882C9.36596 9.92936 9.65537 9.55309 9.74433 9.37503C9.83343 9.19683 9.78882 9.04088 9.72196 8.9073C9.65537 8.77372 9.12144 7.45962 8.89902 6.9249C8.68213 6.40453 8.46222 6.4748 8.29823 6.46664C8.14268 6.45888 7.96461 6.4573 7.78655 6.4573C7.60861 6.4573 7.31934 6.52402 7.07455 6.79145C6.8299 7.05874 6.14027 7.70454 6.14027 9.01864C6.14027 10.3327 7.09693 11.6022 7.23038 11.7804C7.36382 11.9586 9.11301 14.6552 11.7911 15.8115C12.4281 16.0869 12.9253 16.251 13.3131 16.374C13.9527 16.5772 14.5346 16.5485 14.9947 16.4798C15.5077 16.4031 16.5741 15.8339 16.7968 15.2104C17.0192 14.5867 17.0192 14.0522 16.9523 13.9407C16.8857 13.8294 16.7077 13.7627 16.4408 13.629Z"
            fill="currentColor"
          />
        </svg>
      </a>
    </div>
  )
}
