import { LossePlaatjie } from '@ubo/losse-sjedel'
import ContentFields, {
  ContentFieldsContent,
  ContentFieldsLinks,
  ContentFieldsSubtitle,
  ContentFieldsTitle
} from '~/components/elements/ContentFields'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentImageLeftBlock({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentImageLeftBlock" className="section sm:bg-white">
      <div className="container space">
        <div className="bg-ftf-mine-shaft sm:box--rounded sm:box--shadow overflow-hidden">
          <div className="sm:hidden text-white">
            <ContentFieldsTitle fields={fields} type="notable" />
            <ContentFieldsSubtitle fields={fields} type="notable" />
          </div>

          <div className="grid md:grid-cols-2 sm:grid-cols-5 max-sm:space">
            <div className="relative max-sm:col-span-1 max-md:col-span-2">
              <div className="max-sm:hidden absolute right-0 max-xs:bottom-0 xs:top-0 xs:h-full max-xs:w-full max-xs:h-10 xs:w-9 pointer-events-none bg-gradient-to-t xs:bg-gradient-to-l to-transparent from-ftf-mine-shaft z-10" />
              <LossePlaatjie
                src={fields?.image}
                className="sm:absolute left-0 top-0 w-full h-full object-cover max-sm:box--rounded max-sm:max-w-[300px] max-sm:mx-auto max-sm:aspect-[9/11]"
                alt={fields?.title}
                maxwidth={1000}
              />
            </div>
            <div className="max-sm:hidden lg:p-10 xl:px-20 xl:py-14 p-4 sm:p-8 flex items-center max-sm:col-span-1 max-md:col-span-3">
              <div className="lg:py-10 text-white">
                <ContentFields fields={fields} type="notable" />
              </div>
            </div>
          </div>

          <div className="sm:hidden text-white text-center px-4">
            <div className="children-p:!text-lg children-p:!font-bold">
              <ContentFieldsContent fields={fields} type="banner" />
            </div>
            <ContentFieldsLinks fields={fields} type="notable" className="justify-center flex flex-col children-a:min-w-[250px]" />
          </div>
        </div>
      </div>
    </section>
  )
}
