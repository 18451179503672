import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import { ContentFieldsSubtitle, ContentFieldsTitle } from '~/components/elements/ContentFields'
import Cylinder from '~/shapes/Cylinder'
import Content from '~/components/elements/Content'

export default function BannerWithoutMedia({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section
      data-component="BannerWithoutMedia"
      className="relative overflow-hidden section--bottom bg-gradient-to-b from-ftf-mine-shafter to-ftf-dove-gray no--header"
    >
      <div className="container 2xl:max-w-screen-2xl space relative z-10">
        <Breadcrumbs />
        <div className="section--top max-w-screen-xl mx-auto text-white">
          <div className="max-w-2xl">
            <ContentFieldsTitle fields={fields} type="banner-notable" />
            <ContentFieldsSubtitle fields={fields} type="banner" />
          </div>
          {fields?.description && (
            <div className="grid sm:grid-cols-12 sm:gap-6 md:gap-10 lg:gap-20 mt-10 lg:mt-16">
              <div className="sm:col-span-5" />
              <div className="sm:col-span-7">
                <Content className="content max-w-xl">{fields?.description}</Content>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* 0,441805225653207 aspect ratio */}
      <Cylinder className="text-ftf-punch absolute max-sm:opacity-25 max-2xl:opacity-50 top-[150px] lg:top-[225px] max-lg:-right-[400px] lg:-left-[600px] w-[500px] h-[221px] lg:w-[700px] lg:h-[309px]" />
    </section>
  )
}
