import { LossePlaatjie } from '@ubo/losse-sjedel'
import { ContentFieldsTitle } from '~/components/elements/ContentFields'
import type { Page_Flexcontent_Flex_Posts, Werknemer } from '~/graphql/types'
import Logo from '~/shapes/Logo'

export default function PostsEmployees({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const shuffledEmployees = fields?.employees?.edges?.sort(() => Math.random() - 0.5)

  return (
    <section data-component="PostsEmployees" className="section bg-white">
      <div className="container">
        <span className="sub--title text-ftf-punch mb-8 xl:text-2xl">{fields?.subtitle}</span>
        <div className="text-ftf-mine-shaft">
          <ContentFieldsTitle fields={fields} type="notable" />
        </div>

        <div className="mt-10 lg:mt-16 grid grid-cols-2 md:grid-cols-3 gap-x-3 sm:gap-x-4 lg:gap-x-8 gap-y-4 sm:gap-y-10 lg:gap-y-16">
          {shuffledEmployees?.map((_employee) => {
            const employee = _employee?.node as Werknemer

            return (
              <div key={employee?.databaseId} className="rounded-3xl overflow-hidden relative">
                {employee?.recap?.image && (
                  <LossePlaatjie
                    src={employee?.recap?.image}
                    alt={employee?.title}
                    className="w-full aspect-[8/10] object-cover"
                    maxwidth={700}
                  />
                )}
                {!employee?.recap?.image && (
                  <div className="w-full aspect-[8/10] bg-ftf-mine-shaft flex--center">
                    <Logo className="opacity-75 w-[30%] h-[30%] sm:h-[40%] sm:w-[40%] lg:w-[60%] lg:h-[60%] relative bottom-10 sm:bottom-12 lg:bottom-10" />
                  </div>
                )}
                <div className="absolute bottom-0 left-0 right-0 p-4 sm:p-6 lg:px-10 lg:py-8 bg-gradient-to-t from-ftf-mine-shaft from-10% to-transparent">
                  <h3 className="text-white font-black sm:text-xl lg:text-2xl mb-1 lg:mb-2">{employee?.title}</h3>
                  <h4 className="text-ftf-punch max-sm:text-xs max-lg:text-sm">{employee?.recap?.function}</h4>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
