import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import ContentFields from '~/components/elements/ContentFields'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentUsps({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentUsps" className="section">
      <div className="container">
        {(fields?.title || fields?.subtitle || fields?.description) && (
          <div className="text-white mb-10 lg:mb-20 sm:text-center">
            <ContentFields fields={fields} />
          </div>
        )}

        <div className="grid sm:grid-cols-3 gap-8 lg:gap-10 xl:gap-20">
          {fields?.usps?.map((item, index) => (
            <div key={index}>
              <LossePlaatjie src={item?.icon} alt={item?.title} className="mb-3 sm:mb-4 w-12 h-12 object-contain" maxwidth={100} />
              <h3 className="text-white text-lg sm:text-xl xl:text-2xl font-black mb-3 sm:mb-4">{item?.title}</h3>
              <Content className="content text-white/75">{item?.description}</Content>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
