import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import { ContentFieldsLinks, ContentFieldsSubtitle, ContentFieldsTitle } from '~/components/elements/ContentFields'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentCta({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentCta" className="section bg-white">
      <div className="container lg:max-w-[900px] max-sm:space ">
        <div className="xs:pl-10 sm:pl-20">
          <div className="bg-ftf-punch rounded-3xl flex max-xs:flex-col max-xs:gap-4">
            <div className="xs:w-[30%] relative">
              <LossePlaatjie
                src={fields?.image}
                alt={fields?.title}
                maxwidth={400}
                className="max-xs:max-w-[200px] max-xs:-mt-20 max-xs:mx-auto xs:absolute xs:-left-[35%] lg:-left-[47%] xs:-top-[10%] xs:h-[120%] xs:w-[120%] sm:w-[110%] object-contain z-10"
              />
            </div>
            <div className="xs:w-[70%] lg:-ml-16 max-xs:pl-6 pr-6 py-6 sm:py-10 sm:pr-10 text-white lg:children-h2:text-4xl">
              <ContentFieldsTitle fields={fields} />
              <ContentFieldsSubtitle fields={fields} />
              {fields?.description && <Content className="content children-p:font-light -mt-5">{fields?.description}</Content>}
              <ContentFieldsLinks fields={fields} buttons="alternative" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
