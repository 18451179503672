import type { Page_Flexcontent_Flex_Posts, Branche as PostProps } from '~/graphql/types'
import Branche from './post-types/Branche'
import ContentFields from '~/components/elements/ContentFields'
import Slider, { Slide, SliderNavigation } from '~/components/elements/Slider'
import { Navigation } from 'swiper/modules'
import Hyperjump from '~/shapes/Hyperjump'

export default function PostsBranche({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section data-component="PostsBranche" className="section bg-ftf-mine-shaft relative overflow-hidden">
      <div className="container relative z-10">
        <div className="lg:px-10 xl:px-20">
          <div className="max-w-md text-white max-sm:px-4">
            <ContentFields fields={fields} type="notable" />
          </div>
          <div className="space--top">
            <Slider
              breakpoints={{
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 20
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20
                },
                640: {
                  slidesPerView: 3,
                  spaceBetween: 10
                },
                420: {
                  slidesPerView: 2,
                  spaceBetween: 20
                },
                0: {
                  slidesPerView: 2,
                  spaceBetween: 10
                }
              }}
              modules={[Navigation]}
              navigation={{
                prevEl: `.slider-prev`,
                nextEl: `.slider-next`
              }}
            >
              {fields?.branches?.nodes?.map((post, index) => (
                <Slide key={index}>
                  <Branche data={post as PostProps} />
                </Slide>
              ))}
              <div className="flex items-center justify-center gap-4 lg:gap-6 mt-10">
                <SliderNavigation />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <Hyperjump className="max-sm:hidden absolute z-0 top-1/2 -translate-y-1/2 right-0 opacity-20 max-lg:w-full max-lg:h-full" />
    </section>
  )
}
