import clsx from 'clsx'

export default function CylinderCutTopCorner({ className = undefined }: { className?: string }) {
  return (
    <svg
      width="393"
      height="257"
      viewBox="0 0 393 257"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('pointer-events-none', className)}
    >
      <g clipPath="url(#clip0_1902_2201)">
        <path
          d="M362.136 225.298C320.505 266.939 253.007 266.939 211.376 225.298L16.1509 30.0275C-25.481 -11.6141 -25.481 -79.1273 16.1509 -120.769C57.7827 -162.41 125.28 -162.41 166.912 -120.769L362.136 74.5015C403.768 116.143 403.768 183.656 362.136 225.298Z"
          fill="#DE362F"
        />
      </g>
    </svg>
  )
}
