import clsx from 'clsx'

export default function Quote({ className = undefined }: { className?: string }) {
  return (
    <svg
      width="125"
      height="104"
      viewBox="0 0 125 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('pointer-events-none', className)}
    >
      <path
        d="M39.8487 47.1327C36.7822 46.2497 33.7157 45.8016 30.7328 45.8016C26.1265 45.8016 22.2829 46.8545 19.3025 48.1438C22.1758 37.6228 29.078 19.4691 42.8278 17.4248C44.1011 17.2354 45.1446 16.3144 45.492 15.0747L48.4972 4.32386C48.7505 3.41468 48.6003 2.44148 48.0819 1.65247C47.5634 0.863467 46.7301 0.337026 45.7963 0.209008C44.7816 0.0705402 43.7472 0 42.722 0C26.2179 0 9.8731 17.2301 2.97604 41.901C-1.0726 56.3748 -2.25977 78.1352 7.71295 91.8318C13.2936 99.4959 21.4352 103.589 31.9121 103.997C31.9552 103.999 31.997 104 32.0401 104C44.967 104 56.4299 95.2922 59.917 82.8261C62.0001 75.3737 61.0584 67.5581 57.2631 60.8136C53.5084 54.145 47.3244 49.2842 39.8487 47.1327Z"
        fill="currentColor"
      />
      <path
        d="M121.263 60.8142C117.508 54.1443 111.324 49.2836 103.848 47.1321C100.782 46.2491 97.7149 45.801 94.7333 45.801C90.1269 45.801 86.282 46.8539 83.3016 48.1432C86.1749 37.6224 93.0772 19.4689 106.828 17.4246C108.102 17.2351 109.144 16.3142 109.493 15.0745L112.498 4.32381C112.751 3.41463 112.601 2.44145 112.083 1.65245C111.565 0.863456 110.732 0.337022 109.797 0.209006C108.784 0.0705393 107.749 0 106.723 0C90.2183 0 73.8734 17.2299 66.9749 41.9004C62.9275 56.3741 61.7403 78.1342 71.7145 91.8332C77.2939 99.4959 85.4369 103.59 95.9126 103.997C95.9557 103.999 95.9975 104 96.0419 104C108.968 104 120.432 95.2923 123.919 82.8264C126 75.374 125.057 67.5572 121.263 60.8142Z"
        fill="currentColor"
      />
    </svg>
  )
}
