import { LosseLink } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Detail } from '~/graphql/types'

export default function DetailTextAndContents({ fields }: { fields: Page_Flexcontent_Flex_Detail }) {
  return (
    <section data-component="DetailTextAndContents" className="section--detail bg-white">
      <div className="container xl:max-w-screen-xl">
        <div className="flex max-sm:flex-col text-ftf-mine-shaft gap-6 sm:gap-10 lg:gap-32">
          <div className="sm:w-3/5 lg:w-4/6 lg:col-span-8">
            <Content className="content children-p:!font-bold children-p:text-base sm:children-p:text-lg">{fields?.description}</Content>
          </div>
          {fields?.sections && fields?.sections.length > 0 && (
            <aside className="sm:w-2/5 lg:w-2/6">
              <div className="bg-white rounded-2xl lg:rounded-3xl p-4 sm:p-6 lg:p-8 xl:p-10 box--detail--shadow relative sm:-mt-[calc(theme(space.14)+theme(space.10))] lg:-mt-[calc(theme(space.16)+theme(space.10))]">
                <div className="sm:text-lg lg:text-xl font-bold sm:mb-4">{fields?.title}</div>
                {fields?.subtitle && <div className="mb-2 sm:mb-4 max-sm:text-sm">{fields?.subtitle}</div>}
                <ul className="pl-2 flex flex-col gap-2 pt-2 lg:-4">
                  {fields?.sections?.map((section, index) => (
                    <li key={index}>
                      <LosseLink
                        to={section?.link?.url}
                        target={section?.link?.target}
                        className="flex items-center gap-2 text-ftf-mine-shaft/75 hover:text-ftf-mine-shaft font-bold hover:underline"
                      >
                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.84969 5.00003C5.84969 5.17925 5.78126 5.35844 5.64469 5.49508L1.34487 9.79486C1.07135 10.0684 0.627884 10.0684 0.354472 9.79486C0.0810613 9.52145 0.0810612 9.07807 0.354472 8.80452L4.15919 5.00003L0.354605 1.19551C0.0811938 0.921988 0.0811937 0.478652 0.354605 0.205263C0.628016 -0.0683909 1.07148 -0.0683909 1.34501 0.205263L5.64482 4.50497C5.78142 4.64168 5.84969 4.82087 5.84969 5.00003Z"
                            fill="currentColor"
                          />
                        </svg>
                        {section?.link?.title}
                      </LosseLink>
                    </li>
                  ))}
                </ul>
              </div>
            </aside>
          )}
        </div>
      </div>
    </section>
  )
}
