import clsx from 'clsx'

export default function FilledCylindersTitled({ className = undefined }: { className?: string }) {
  return (
    <svg
      width="1023"
      height="2029"
      viewBox="0 0 1023 2029"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('pointer-events-none', className)}
    >
      <path
        d="M944.423 944.644C840.189 1048.9 671.195 1048.9 566.961 944.644L78.1754 455.744C-26.0585 351.485 -26.0585 182.452 78.1754 78.1938C182.409 -26.0646 351.403 -26.0646 455.637 78.1938L944.423 567.094C1048.66 671.352 1048.66 840.385 944.423 944.644Z"
        fill="currentColor"
      />
      <path
        d="M87.04 1092.99C191.274 988.727 360.268 988.727 464.502 1092.99L944.423 1573.02C1048.66 1677.28 1048.66 1846.31 944.423 1950.57C840.189 2054.83 671.195 2054.83 566.961 1950.57L87.04 1470.54C-17.1939 1366.28 -17.1939 1197.24 87.04 1092.99Z"
        fill="currentColor"
      />
    </svg>
  )
}
