import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import Cylinder from '~/shapes/Cylinder'
import ContentFields from '~/components/elements/ContentFields'
import Form from '~/components/elements/Form'
import { useState } from 'react'
import useInfo from '~/utils/useInfo'
import Socials from '~/components/elements/Socials'

export default function FormContact({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const [isSend, setSend] = useState(false)
  const info = useInfo()

  return (
    <section data-component="BannerWithFlow" className="relative overflow-hidden section--bottom">
      <div className="container 2xl:max-w-screen-2xl space--top relative z-10">
        <Breadcrumbs />
        <div className="section--top max-w-screen-xl mx-auto text-white">
          <ContentFields fields={fields} type="banner-notable" />
          <div className="grid sm:grid-cols-12 gap-10 lg:gap-20 mt-10 sm:mt-16 lg:mt-20">
            <div className="sm:col-span-4 pt-6 lg:pt-10">
              <h3 className="sub--title xl:text-2xl">Gegevens</h3>
              <div className="grid max-xs:grid-cols-1 max-sm:grid-cols-2 gap-6 mt-6 font-bold">
                <div>
                  {info.addressRuleOne}
                  <br />
                  {info.addressRuleTwo}
                  <br />
                  {info.addressRuleThree}
                  <br />
                </div>
                <div className="flex flex-col gap-2">
                  <a href={`tel:${info.phonenumber}`} target="_blank" rel="noreferrer" className="flex items-center hover:underline">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-4 h-4 mr-3 max-sm:hidden"
                    >
                      <g clipPath="url(#clip0_1113_286)">
                        <path
                          d="M10.6501 12.1958C8.46644 11.2711 6.72994 9.5306 5.8104 7.34475L8.3604 4.79025L3.6654 0.0915L1.28715 2.469C0.874647 2.88386 0.548507 3.3764 0.327596 3.91812C0.106685 4.45984 -0.00461116 5.03999 0.000146326 5.625C0.000146326 11.061 6.93915 18 12.3751 18C12.9601 18.0051 13.5402 17.894 14.0819 17.673C14.6235 17.4521 15.1159 17.1258 15.5304 16.713L17.9086 14.3348L13.2099 9.636L10.6501 12.1958ZM14.4691 15.6525C14.1937 15.9255 13.8668 16.1411 13.5073 16.2866C13.1478 16.4321 12.7629 16.5046 12.3751 16.5C7.7004 16.5 1.50015 10.2998 1.50015 5.625C1.49573 5.23712 1.56837 4.85223 1.71385 4.49264C1.85933 4.13306 2.07476 3.80594 2.34765 3.53025L3.6654 2.2125L6.24315 4.79025L4.0419 6.9915L4.22565 7.452C4.76658 8.89898 5.61222 10.2128 6.70526 11.3044C7.7983 12.396 9.11321 13.24 10.5609 13.779L11.0154 13.9523L13.2099 11.757L15.7876 14.3348L14.4691 15.6525ZM10.5001 1.5V0C12.4886 0.0021837 14.395 0.793061 15.801 2.19911C17.2071 3.60516 17.998 5.51155 18.0001 7.5H16.5001C16.4984 5.90925 15.8656 4.38416 14.7408 3.25933C13.616 2.1345 12.0909 1.50179 10.5001 1.5ZM10.5001 4.5V3C11.6933 3.00119 12.8372 3.47568 13.6808 4.31934C14.5245 5.16299 14.999 6.30689 15.0001 7.5H13.5001C13.5001 6.70435 13.1841 5.94129 12.6215 5.37868C12.0589 4.81607 11.2958 4.5 10.5001 4.5Z"
                          fill="currentColor"
                        />
                      </g>
                    </svg>
                    {info.phonenumber}
                  </a>
                  <a href={`mailto:${info.emailAddress}`} target="_blank" rel="noreferrer" className="flex items-center hover:underline">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-4 h-4 mr-3 max-sm:hidden"
                    >
                      <g clipPath="url(#clip0_1113_275)">
                        <path
                          d="M15.75 0.750244H2.25C1.65326 0.750244 1.08097 0.987297 0.65901 1.40925C0.237053 1.83121 0 2.40351 0 3.00024L0 17.2502H18V3.00024C18 2.40351 17.7629 1.83121 17.341 1.40925C16.919 0.987297 16.3467 0.750244 15.75 0.750244ZM2.25 2.25024H15.75C15.9489 2.25024 16.1397 2.32926 16.2803 2.46991C16.421 2.61057 16.5 2.80133 16.5 3.00024V3.50049L10.5915 9.40974C10.1688 9.83073 9.59656 10.0671 9 10.0671C8.40344 10.0671 7.83118 9.83073 7.4085 9.40974L1.5 3.50049V3.00024C1.5 2.80133 1.57902 2.61057 1.71967 2.46991C1.86032 2.32926 2.05109 2.25024 2.25 2.25024ZM1.5 15.7502V5.62524L6.348 10.4702C7.05197 11.1724 8.00569 11.5668 9 11.5668C9.99431 11.5668 10.948 11.1724 11.652 10.4702L16.5 5.62524V15.7502H1.5Z"
                          fill="currentColor"
                        />
                      </g>
                    </svg>
                    {info.emailAddress}
                  </a>
                </div>
                <div>
                  <a href={info.whatsapp} target="_blank" rel="noreferrer" className="btn--la-palma !text-sm sm:w-[175px] justify-center">
                    WhatsApp ons
                  </a>
                </div>
                <div>
                  <Socials />
                </div>
              </div>
            </div>
            <div className="sm:col-span-8 bg-white box--rounded box--large--shadow p-4 xs:p-6 sm:p-10 md:p-16 lg:p-24 text-black">
              {!isSend && <h3 className="mb-6 sm:mb-10 text-lg font-black xl:text-2xl text-ftf-mine-shaft">Stuur ons een bericht</h3>}
              <Form
                data={fields?.form}
                onSubmitDone={() => {
                  setSend(true)
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* 0,441805225653207 aspect ratio */}
      <Cylinder className="text-ftf-punch absolute max-sm:opacity-25 max-2xl:opacity-50 top-[25px] lg:top-[100px] max-lg:-right-[400px] lg:-left-[600px] w-[500px] h-[221px] lg:w-[700px] lg:h-[309px]" />
    </section>
  )
}
