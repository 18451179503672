import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import ContentDefault from './ContentDefault'
import ContentBlocks from './ContentBlocks'
import ContentImageRightDarkBg from './ContentImageRightDarkBg'
import ContentImageLeftBlock from './ContentImageLeftBlock'
import ContentImageLeftLightBg from './ContentImageLeftLightBg'
import ContentFlow from './ContentFlow'
import ContentUsps from './ContentUsps'
import ContentCta from './ContentCta'
import ContentWithBgForm from './ContentWithBgForm'
import ContentAccordion from './ContentAccordion'
import ContentLightBgAndStripes from './ContentLightBgAndStripes'
import ContentIdAccordion from './ContentIdAccordion'
import ContentQuoteWithImage from './ContentQuoteWithImage'

export default function ContentShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: ContentDefault,
    blocks: ContentBlocks,
    image_right_dark_bg: ContentImageRightDarkBg,
    image_left_block: ContentImageLeftBlock,
    image_left_light_bg: ContentImageLeftLightBg,
    white_bg_and_stripes: ContentLightBgAndStripes,
    flow: ContentFlow,
    usps: ContentUsps,
    cta: ContentCta,
    with_bg_form: ContentWithBgForm,
    accordion: ContentAccordion,
    id_accordion: ContentIdAccordion,
    quote_with_image: ContentQuoteWithImage
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
