import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import CylinderCutTopCorner from '~/shapes/CylinderCutTopCorner'
import FilledCylindersTitled from '~/shapes/FilledCylindersTitled'

export default function PostCaseHighlighted({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section data-component="PostCaseHighlighted" className="relative overflow-hidden">
      <div className="container relative z-10 pb-4 sm:pb-20 lg:pb-36 xl:pb-52 pt-6 sm:pt-10 lg:pt-16 xl:pt-32">
        <div className="md:grid grid-cols-2 relative z-10">
          <div className="order-2 pl-10 md:pl-20">
            <h2 className="title text-3xl md:text-4xl lg:text-5xl xl:text-7xl text-white">
              <span className="block">Wat</span>
              <span className="block pl-10 md:pl-12 lg:pl-14">klanten</span>
              <span className="block pl-20 md:pl-24 lg:pl-28">zeggen</span>
            </h2>
          </div>
          <div className="max-md:mt-36 md:pt-16 lg:pt-32 pb-12 order-1 max-sm:px-4">
            <div className="flex items-center gap-y-2 gap-x-4 lg:gap-8 flex-wrap">
              <h3 className="text-ftf-punch title max-sm:text-xl sm:text-xl lg:text-2xl">{fields?.post?.recap?.company}</h3>
              <h4 className="text-white lg:text-lg">- {fields?.post?.recap?.name}</h4>
            </div>
            <Content className="content text-white lg:text-lg xl:children:!leading-8 children:!font-medium mt-10 lg:pr-20">
              {fields?.post?.recap?.quote}
            </Content>
            <div className="space--top">
              <LosseLink
                to={fields?.post?.uri}
                className="flex items-center font-semibold gap-4 text-white opacity-90 max-lg:text-sm group hover:underline hover:opacity-100"
              >
                Lees hele case
                <svg
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 group-hover:translate-x-2 transition-all"
                >
                  <path
                    d="M-9.61651e-07 22C-7.71454e-07 26.3512 1.29028 30.6047 3.70767 34.2225C6.12506 37.8404 9.56099 40.6602 13.581 42.3253C17.6009 43.9905 22.0244 44.4261 26.292 43.5773C30.5596 42.7284 34.4796 40.6331 37.5563 37.5563C40.6331 34.4796 42.7284 30.5596 43.5773 26.292C44.4261 22.0244 43.9905 17.6009 42.3253 13.581C40.6602 9.56099 37.8404 6.12506 34.2225 3.70767C30.6047 1.29028 26.3512 2.66285e-06 22 2.85305e-06C16.1672 0.00631262 10.575 2.32619 6.45062 6.45062C2.32618 10.5751 0.00630743 16.1672 -9.61651e-07 22H-9.61651e-07ZM40.3333 22C40.3333 25.626 39.2581 29.1705 37.2436 32.1854C35.2291 35.2003 32.3658 37.5502 29.0159 38.9378C25.6659 40.3254 21.9797 40.6884 18.4233 39.9811C14.867 39.2737 11.6003 37.5276 9.03637 34.9636C6.47241 32.3997 4.72633 29.133 4.01894 25.5767C3.31154 22.0203 3.6746 18.3341 5.06221 14.9841C6.44981 11.6342 8.79964 8.77089 11.8145 6.75639C14.8294 4.7419 18.374 3.66667 22 3.66667C26.8607 3.672 31.5207 5.60526 34.9577 9.04227C38.3947 12.4793 40.328 17.1393 40.3333 22Z"
                    fill="currentColor"
                  />
                  <path
                    d="M27.3474 22C27.3481 22.4012 27.2695 22.7986 27.1161 23.1694C26.9627 23.5401 26.7376 23.8769 26.4537 24.1603L18.3656 32.2468L16.2053 30.0865L24.2918 22L16.2053 13.9135L18.3656 11.7532L26.4521 19.8397C26.7364 20.1231 26.9618 20.4598 27.1154 20.8305C27.2691 21.2013 27.3479 21.5987 27.3474 22Z"
                    fill="currentColor"
                  />
                </svg>
              </LosseLink>
            </div>
          </div>
        </div>
        <div className="absolute max-md:hidden right-[300px] md:right-[350px] lg:right-[200px] xl:right-[50px] -top-[150px] w-[300px] md:w-[400px] lg:w-[600px] xl:w-[850px] h-[600px] md:h-[800px] lg:h-[1200px] xl:h-[1700px]">
          <FilledCylindersTitled className="h-full text-ftf-punch" />
        </div>
        {/* 0,653944020356234 aspect ratio */}
        <CylinderCutTopCorner className="absolute -left-10 top-0 md:hidden w-[325px] h-[213px]" />
      </div>
      {fields?.post?.recap?.backgroundImage && (
        <>
          <div className="absolute left-0 top-0 w-full h-full z-0">
            <LossePlaatjie src={fields.post.recap.backgroundImage} className="w-full h-full object-cover grayscale" maxwidth={2000} />
          </div>
          <div className="absolute left-0 top-0 w-full h-full bg-black/70" />
        </>
      )}
    </section>
  )
}
