/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { LosseLink, useLoaderData, useLocation, useLosseLayout } from '@ubo/losse-sjedel'
import { AnimatePresence } from 'framer-motion'
import HeaderSubMenu from './HeaderSubmenu'
import type { HeaderLoaderData } from './Header'
import type { Component_Header_MenuHeader_Submenu } from '~/graphql/types'
import clsx from 'clsx'

export default function Menu({ setMobileMenuOpen }: { setMobileMenuOpen: (open: boolean) => void }) {
  const { header } = useLoaderData<HeaderLoaderData>()
  const [currentIndex, setCurrentIndex] = useState(-1)
  const { isSticky } = useLosseLayout()
  const location = useLocation()
  const parentPath = location.pathname.split('/')[1]

  function handleSubmenu(open: boolean, index: number) {
    if (open) {
      setCurrentIndex(-1)
    } else {
      setCurrentIndex(index)
    }
  }

  useEffect(() => {
    if (currentIndex !== -1) {
      setCurrentIndex(-1)
    }
  }, [location.pathname, location.hash, location.search])

  return (
    <>
      {header?.menuHeader?.map((edge, index) => {
        const submenuOpen = currentIndex === index
        const partiallyCurrent = edge?.link?.url?.replace(/\//g, '') === parentPath

        return (
          <li
            onMouseEnter={() => {
              if (window.innerWidth >= 1280) setCurrentIndex(index)
            }}
            onMouseLeave={() => {
              if (window.innerWidth >= 1280) setCurrentIndex(-1)
            }}
            key={index}
          >
            <div className="flex flex-wrap items-center">
              <LosseLink
                className={clsx(
                  'font-semibold text-white xl:text-ftf-mine-shaft px-2 py-2 xl:py-3 group hover:bg-ftf-punch hover:text-white hover:!no-underline xl:text-sm text-xl flex items-center max-xl:rounded-xl',
                  isSticky && 'xl:rounded-xl'
                )}
                to={edge?.link?.url || '/'}
              >
                {({ isActive }) => (
                  <>
                    {(isActive || partiallyCurrent) && (
                      <div className="max-lg:top-[1px] relative w-2 h-2 rounded-full bg-ftf-punch group-hover:bg-white mr-2" />
                    )}
                    {edge?.link?.title}
                  </>
                )}
              </LosseLink>
              {edge?.useSubmenu && (
                <>
                  <div
                    onClick={() => handleSubmenu(submenuOpen, index)}
                    className="max-xl:hidden ml-1 text-ftf-mine-shaft max-xl:bg-white rounded-full max-xl:h-8 max-xl:w-8 flex--center hover:bg-ftf-seashell hover:cursor-pointer"
                    aria-label={`Submenu ${edge?.link?.title}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={
                        submenuOpen
                          ? {
                              transform: 'rotate(180deg)'
                            }
                          : {
                              transform: 'rotate(0deg)'
                            }
                      }
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="transition-all lg:w-5 lg:h-5 w-7 h-7"
                    >
                      <polyline points="6 9 12 15 18 9" />
                    </svg>
                  </div>
                  <div
                    onClick={() => handleSubmenu(submenuOpen, index)}
                    className="xl:hidden ml-1 relative top-[2px] text-white rounded-full flex--center hover:bg-ftf-seashell hover:text-ftf-mine-shaft hover:cursor-pointer"
                    aria-label={`Submenu ${edge?.link?.title}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="-rotate-90 w-7 h-7"
                    >
                      <polyline points="6 9 12 15 18 9" />
                    </svg>
                  </div>
                </>
              )}
            </div>
            <AnimatePresence>
              {edge?.useSubmenu && submenuOpen && (
                <HeaderSubMenu
                  key={index}
                  fields={edge.submenu as Component_Header_MenuHeader_Submenu}
                  setCurrentIndex={setCurrentIndex}
                  setMobileMenuOpen={setMobileMenuOpen}
                />
              )}
            </AnimatePresence>
          </li>
        )
      })}
    </>
  )
}
