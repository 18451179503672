import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Vacature as Props } from '~/graphql/types'

export default function Vacature({ data, type = 'compact' }: { data: Props; type?: 'compact' | 'blog' }) {
  if (!data.title) return null

  const categories = data?.categories?.edges?.filter((edge) => edge?.node?.databaseId !== 1) || []

  return (
    <LosseLink to={data.uri} className="group block h-full">
      <div className="flex flex-col h-full w-full">
        <div className="aspect-[16/12] xs:aspect-[16/11] sm:aspect-[16/12] md:aspect-video w-full rounded-2xl lg:rounded-3xl overflow-hidden relative z-10">
          <LossePlaatjie src={data.featuredImage?.node} className="w-full h-full object-cover" maxwidth={700} title={data.title} />
          <div className="absolute left-0 top-0 w-full h-full lg:py-6 lg:px-6 xl:py-8 py-4 xl:px-10 px-4 md:px-6 flex flex-col justify-between bg-black/[.55] sm:group-hover:bg-transparent group-focus:bg-transparent transition-all">
            <h3 className="swiper-no-swiping line-clamp-3 sub--title text-white xl:text-2xl sm:font-black sm:group-hover:text--shadow">
              {data.title}
            </h3>
            <div className="flex items-center justify-end font-semibold gap-4 text-white max-lg:text-sm group-hover:underline opacity-90 group-hover:opacity-100">
              Bekijk vacature
              <svg
                width="44"
                height="44"
                viewBox="0 0 44 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="lg:w-10 lg:h-10 h-6 w-6 group-hover:translate-x-2 transition-transform"
              >
                <path
                  d="M-9.61651e-07 22C-7.71454e-07 26.3512 1.29028 30.6047 3.70767 34.2225C6.12506 37.8404 9.56099 40.6602 13.581 42.3253C17.6009 43.9905 22.0244 44.4261 26.292 43.5773C30.5596 42.7284 34.4796 40.6331 37.5563 37.5563C40.6331 34.4796 42.7284 30.5596 43.5773 26.292C44.4261 22.0244 43.9905 17.6009 42.3253 13.581C40.6602 9.56099 37.8404 6.12506 34.2225 3.70767C30.6047 1.29028 26.3512 2.66285e-06 22 2.85305e-06C16.1672 0.00631262 10.575 2.32619 6.45062 6.45062C2.32618 10.5751 0.00630743 16.1672 -9.61651e-07 22H-9.61651e-07ZM40.3333 22C40.3333 25.626 39.2581 29.1705 37.2436 32.1854C35.2291 35.2003 32.3658 37.5502 29.0159 38.9378C25.6659 40.3254 21.9797 40.6884 18.4233 39.9811C14.867 39.2737 11.6003 37.5276 9.03637 34.9636C6.47241 32.3997 4.72633 29.133 4.01894 25.5767C3.31154 22.0203 3.6746 18.3341 5.06221 14.9841C6.44981 11.6342 8.79964 8.77089 11.8145 6.75639C14.8294 4.7419 18.374 3.66667 22 3.66667C26.8607 3.672 31.5207 5.60526 34.9577 9.04227C38.3947 12.4793 40.328 17.1393 40.3333 22Z"
                  fill="currentColor"
                />
                <path
                  d="M27.3474 22C27.3481 22.4012 27.2695 22.7986 27.1161 23.1694C26.9627 23.5401 26.7376 23.8769 26.4537 24.1603L18.3656 32.2468L16.2053 30.0865L24.2918 22L16.2053 13.9135L18.3656 11.7532L26.4521 19.8397C26.7364 20.1231 26.9618 20.4598 27.1154 20.8305C27.2691 21.2013 27.3479 21.5987 27.3474 22Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
        </div>
        {type === 'blog' && (
          <div className="-mt-5 bg-ftf-silver-chalice flex-grow flex flex-col justify-between p-3 sm:p-4 lg:p-6 rounded-b-2xl lg:rounded-b-3xl relative z-0 group-hover:bg-ftf-punch">
            {categories.length > 0 && (
              <div className="flex items-center flex-wrap mt-5 gap-2 pb-2 -mr-3 sm:-mr-4 lg:-mr-6">
                {categories.map((category) => (
                  <div key={category?.node?.databaseId} className="tag lg:!text-s">
                    {category?.node?.name}
                  </div>
                ))}
              </div>
            )}
            <Content className="mt-2 lg:mt-3 content text-white content--recap">{data?.recap?.excerpt}</Content>
          </div>
        )}
      </div>
    </LosseLink>
  )
}
