import type { Page_Flexcontent_Flex_Posts, Post as PostProps } from '~/graphql/types'
import Post from './post-types/Post'
import clsx from 'clsx'
import { LosseLink } from '@ubo/losse-sjedel'

export default function PostsLatest({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section data-component="PostsLatest" className="section bg-white">
      <div className="container">
        {fields?.title && <h2 className="title text-ftf-mine-shaft text-center mb-0">{fields.title}</h2>}

        <div className="grid-cols-3 gap-2 lg:gap-4 space--detail--top grid--responsive">
          {fields?.posts?.edges?.map((item, index: number) => (
            <div key={index} className="max-xs:min-w-[65%] max-sm:min-w-[55%]">
              <Post data={item.node as PostProps} />
            </div>
          ))}
        </div>

        {fields.links && (
          <div className="flex items-center justify-center gap-2 space--detail--top">
            {fields.links.map((link, index) => (
              <LosseLink
                className={clsx(index === 0 ? 'btn--mine-shaft--outline' : 'btn--la-palma')}
                key={index}
                to={link?.link?.url || '/'}
              >
                {link?.link?.title}
              </LosseLink>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}
