import { LossePlaatjie, losseContentParse } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentQuoteWithImage({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentQuoteWithImage" className="relative overflow-hidden">
      <div className="section container relative z-10 after:absolute after:block after:bg-ftf-mine-shaft after:top-0 after:h-full after:right-full after:w-[2000px] after:-mr-[1px]">
        <div className="w-full sm:w-2/3 xl:w-2/3 py-16 sm:py-20 lg:py-32 relative z-10">
          <blockquote className="content children:!text-2xl sm:children:!text-4xl lg:children:!text-5xl xl:children:!text-6xl xl:children:!leading-[70px] text-white children:!font-black text--shadow--subtle">
            {losseContentParse(fields?.description)}
          </blockquote>
        </div>
        <div className="absolute left-0 top-0 h-full sm:w-[50%] w-full bg-gradient-to-r from-10% from-ftf-mine-shaft  to-transparent" />
      </div>
      <LossePlaatjie src={fields?.image} maxWidth={2000} className="absolute z-0 left-0 top-0 w-full h-full object-cover xl:pl-[12%]" />
    </section>
  )
}
