import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Detail } from '~/graphql/types'
import CylinderTilted from '~/shapes/CylinderTilted'
import Quote from '~/shapes/Quote'

export default function DetailQuote({ fields }: { fields: Page_Flexcontent_Flex_Detail }) {
  const quoteCharacters = fields?.description?.length
  let quoteSize = 'm'

  if (quoteCharacters && quoteCharacters > 650) {
    quoteSize = 'xl'
  } else if (quoteCharacters && quoteCharacters > 400) {
    quoteSize = 'lg'
  }

  return (
    <section data-component="DetailQuote" className="section--detail relative overflow-hidden bg-white" id={fields?.sectionId}>
      <div className="section--detail--top relative z-10">
        <div className="container md:max-w-screen-sm lg:max-w-screen-md text-center relative">
          <Quote className="absolute md:-left-16 max-md:-top-20 lg:-left-28 lg:-top-20 text-ftf-mine-shaft/10 w-[75px] h-[65px] lg:w-[125px] lg:h-[104px]" />
          <Content
            className={clsx(
              quoteSize === 'm' &&
                'children-p:text-2xl sm:children-p:text-3xl lg:children-p:text-4xl children-p:!leading-[40px] sm:children-p:!leading-[50px] lg:children-p:!leading-[60px]',
              quoteSize === 'lg' &&
                'children-p:text-xl sm:children-p:text-2xl lg:children-p:text-3xl children-p:!leading-[30px] sm:children-p:!leading-[40px] lg:children-p:!leading-[50px]',
              quoteSize === 'xl' &&
                'children-p:text-lg sm:children-p:text-xl lg:children-p:text-2xl children-p:!leading-[25px] sm:children-p:!leading-[30px] lg:children-p:!leading-[40px]',
              'content children-p:!font-black text-ftf-mine-shaft'
            )}
          >{`<blockquote>${fields?.description}</blockquote>`}</Content>
          <div className="text-ftf-punch">{fields?.nameAndFunction}</div>
        </div>
      </div>
      <CylinderTilted className="absolute top-0 max-sm:opacity-50 -right-24 lg:-right-32 text-ftf-punch w-[200px] h-[200px] md:w-[300px] md:h-[300px] lg:w-[400px] lg:h-[400px]" />
    </section>
  )
}
