/* eslint-disable react-hooks/exhaustive-deps */
import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { motion, useInView } from 'framer-motion'
import { useRef } from 'react'
import type { Page_Flexcontent_Flex_Banner, Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function Flow({
  fields,
  itemClassName = undefined
}: {
  fields: Page_Flexcontent_Flex_Banner | Page_Flexcontent_Flex_Content
  itemClassName?: string
}) {
  const elementRef = useRef<HTMLDivElement>(null)
  const isInView = useInView(elementRef, { once: true })

  return (
    <div className="flex flex-col gap-3 h-full w-full overflow-auto py-2" ref={elementRef}>
      {fields?.flow?.map((item, index) => (
        <motion.div
          key={index}
          animate={isInView ? 'animate' : 'initial'}
          initial="initial"
          variants={{
            initial: {
              opacity: 0,
              y: -20
            },
            animate: {
              opacity: 1,
              y: 0
            }
          }}
          transition={{
            delay: index * 0.75,
            duration: 0.75
          }}
          className={clsx(
            'flex',
            item?.align === 'left' && 'justify-start',
            item?.align === 'center' && 'justify-center',
            item?.align === 'right' && 'justify-end'
          )}
        >
          <LossePlaatjie src={item?.image} className={clsx('object-contain', itemClassName)} maxwidth={500} />
        </motion.div>
      ))}
    </div>
  )
}
