import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Detail } from '~/graphql/types'

export default function DetailText({ fields }: { fields: Page_Flexcontent_Flex_Detail }) {
  return (
    <section data-component="DetailText" className="section--detail bg-white" id={fields?.sectionId}>
      <div className="container xl:max-w-screen-xl">
        <div className={clsx(fields?.backgroundColor === 'light_grey' && 'bg-ftf-seashell box--rounded lg:py-16 max-sm:p-4 max-lg:p-8')}>
          <div className="max-w-screen-md xl:max-w-screen-lg mx-auto">
            <Content className="content">{fields?.description}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
