import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import DetailPostBanner from './DetailPostBanner'
import DetailTextAndContents from './DetailTextAndContents'
import DetailTextAndImage from './DetailTextAndImage'
import DetailText from './DetailText'
import DetailQuote from './DetailQuote'
import DetailCaseBanner from './DetailCaseBanner'

export default function DetailShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    post_banner: DetailPostBanner,
    case_banner: DetailCaseBanner,
    text_contents: DetailTextAndContents,
    with_image: DetailTextAndImage,
    text: DetailText,
    quote: DetailQuote
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
