import ContentFields from '~/components/elements/ContentFields'
import Flow from '~/components/elements/Flow'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentFlow({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentFlow" className="section bg-gradient-to-b from-ftf-mine-shaft/75 to-ftf-mine-shaft-t-b">
      <div className="container max-w-xl">
        <div className="text-white text-center">
          <ContentFields fields={fields} />
        </div>

        <div className="space--top">
          <Flow key={JSON.stringify(fields?.flow)} fields={fields} itemClassName="max-w-[200px] sm:max-w-[300px]" />
        </div>
      </div>
    </section>
  )
}
