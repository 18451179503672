import { LossePlaatjie } from '@ubo/losse-sjedel'
import ContentFields from '~/components/elements/ContentFields'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import Hyperjump from '~/shapes/Hyperjump'

export default function ContentImageLeftLightBg({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentImageLeftLightBg" className="section bg-white relative overflow-hidden">
      <div className="container relative z-10">
        <div className="grid xs:grid-cols-12 gap-6 sm:gap-10 lg:gap-20">
          <div className="xs:col-span-4 sm:col-span-5">
            <LossePlaatjie
              src={fields?.image}
              alt={fields?.title}
              maxwidth={700}
              className="max-xs:w-[65%] lg:max-w-sm max-w-full mx-auto"
            />
          </div>
          <div className="xs:col-span-8 sm:col-span-7 flex items-center">
            <div className="text-ftf-mine-shafter">
              <ContentFields fields={fields} type="alternative" />
            </div>
          </div>
        </div>
      </div>
      <Hyperjump className="absolute z-0 top-1/2 -translate-y-1/2 right-0 flip--horizontal transform opacity-70 max-lg:w-full max-lg:h-full" />
    </section>
  )
}
