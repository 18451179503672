/* eslint-disable react-hooks/exhaustive-deps */
import { useLosseLayout } from '@ubo/losse-sjedel'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  open?: boolean
  onClose?: () => void
}

export default function Modal({ open, onClose, ...rest }: ModalProps): JSX.Element {
  const layout = useLosseLayout()
  const [isOpen, setOpen] = useState(false)
  const modalRef = useRef<HTMLDivElement>(null)

  function handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') close()
  }

  function handleClickOutside(event: MouseEvent) {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) close()
  }

  function close() {
    setOpen(false)

    if (typeof onClose === 'function') {
      onClose()
    }
  }

  useEffect(() => {
    if (open === undefined) {
      return
    }

    setOpen(open)
  }, [open])

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (isOpen && layout.isScrollable) {
      layout.setScrollable(false)
    }

    if (!isOpen && !layout.isScrollable) {
      layout.setScrollable(true)
    }
  }, [isOpen])

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed flex--center top-0 left-0 w-full h-full z-[99] bg-black/50"
        >
          <div className="container">
            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: -20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: -20 }}
              className="container max-w-[575px] h-full overflow-auto mx-auto"
              ref={modalRef}
            >
              <div className="bg-white shadow relative box--rounded box--shadow">
                <div className="p-4 sm:p-5 md:py-10 md:px-12">{rest.children}</div>
              </div>
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
