import type { Maybe, Page_Flexcontent_Flex_Posts, Post as PostProps, RootQueryToPostConnection } from '~/graphql/types'
import Post from './post-types/Post'

interface PostsRelatedProps extends Page_Flexcontent_Flex_Posts {
  subdata: {
    Related: Maybe<RootQueryToPostConnection>
  }
}

export default function PostsRelated({ fields }: { fields: PostsRelatedProps }) {
  if (fields?.subdata?.Related?.edges?.length === 0) return null

  return (
    <section data-component="PostsRelated" className="section bg-white">
      <div className="container">
        {fields?.title && <h2 className="title text-ftf-mine-shaft text-center">{fields.title}</h2>}

        <div className="grid-cols-3 gap-4 sm:gap-2 lg:gap-4 mt-6 sm:mt-10 lg:mt-16 grid--responsive">
          {fields?.subdata?.Related?.edges?.map((item, index: number) => (
            <div key={index} className="max-sm:min-w-[250px]">
              <Post data={item.node as PostProps} />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
