import { LossePlaatjie } from '@ubo/losse-sjedel'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import DetailBanner from '~/components/elements/DetailBanner'
import type { Page_Flexcontent_Flex_Detail } from '~/graphql/types'
import FilledCylindersTitled from '~/shapes/FilledCylindersTitled'

export default function DetailCaseBanner({ fields }: { fields: Page_Flexcontent_Flex_Detail }) {
  return (
    <section data-component="DetailCaseBanner" className="relative overflow-hidden section--bottom no--header">
      <div className="container 2xl:max-w-screen-2xl relative z-[25] sm:section--detail--bottom space--top">
        <Breadcrumbs />
        <div className="grid sm:grid-cols-2 gap-6 sm:gap-10 lg:gap-20 mt-10 max-w-screen-xl mx-auto">
          <div className="sm:py-6 text-white flex items-center order-2">
            <div className="w-full">
              <div className="sub--title text-ftf-punch mb-4">{fields?.companyName}</div>
              <DetailBanner fields={fields} />
            </div>
          </div>
        </div>
      </div>
      <div className="absolute max-md:opacity-25 right-[300px] md:right-[350px] lg:right-[200px] xl:right-[50px] -top-[150px] w-[300px] md:w-[400px] lg:w-[600px] xl:w-[850px] h-[600px] md:h-[800px] lg:h-[1200px] xl:h-[1700px] z-20">
        <FilledCylindersTitled className="h-full text-ftf-punch" />
      </div>
      <LossePlaatjie
        src={fields?.image}
        alt={fields?.title}
        className="absolute left-0 top-0 w-full h-full object-cover"
        maxwidth={2000}
        loading="eager"
      />
      <div className="absolute left-0 top-0 w-full h-full bg-black/[.65] z-10" />
    </section>
  )
}
