import { useLoaderData } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Detail, Post } from '~/graphql/types'
import { ContentFieldsTitle } from './ContentFields'
import Share from './Share'

export default function DetailBanner({ fields }: { fields: Page_Flexcontent_Flex_Detail }) {
  const { page } = useLoaderData<{ page: Post }>()

  return (
    <>
      <ContentFieldsTitle type="banner" fields={fields} />
      <div className="mt-4 lg:mt-5 flex items-center flex-wrap gap-2">
        {page?.categories?.edges
          ?.filter((category) => category.node.databaseId !== 1)
          ?.map((category) => (
            <div key={category?.node?.databaseId} className="tag">
              {category?.node?.name}
            </div>
          ))}
      </div>
      <div className="mt-6 lg:mt-9 xs:flex items-center gap-10 lg:gap-20">
        {page?.recap?.readingTime && (
          <div className="flex items-center">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-3 text-ftf-punch">
              <g clipPath="url(#clip0_1624_3199)">
                <path
                  d="M10 0C4.48591 0 0 4.48591 0 10C0 15.5141 4.48591 20 10 20C15.5141 20 20 15.5141 20 10C20 4.48591 15.5141 0 10 0ZM10 17.8142C5.69113 17.8142 2.18579 14.3089 2.18579 10C2.18579 5.69135 5.69113 2.18579 10 2.18579C14.3089 2.18579 17.8142 5.69135 17.8142 10C17.8142 14.3089 14.3089 17.8142 10 17.8142Z"
                  fill="currentColor"
                />
                <path
                  d="M10.8651 10.0121V5.82084C10.8651 5.35286 10.4859 4.97363 10.0181 4.97363C9.55016 4.97363 9.1709 5.35286 9.1709 5.82084V10.2827C9.1709 10.296 9.17418 10.3085 9.17483 10.3219C9.16368 10.5522 9.24304 10.7861 9.41898 10.9621L12.5742 14.1171C12.9051 14.448 13.4415 14.448 13.7722 14.1171C14.1029 13.7861 14.1031 13.2497 13.7722 12.919L10.8651 10.0121Z"
                  fill="currentColor"
                />
              </g>
            </svg>
            <span className="max-lg:text-sm">
              Leestijd {page?.recap?.readingTime} {page?.recap?.readingTime === 1 ? 'minuut' : 'minuten'}
            </span>
          </div>
        )}
        <div className="text-white/75 max-xs:mt-6">
          <Share />
        </div>
      </div>
    </>
  )
}
