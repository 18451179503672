/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, type PropsWithChildren, useContext, useEffect } from 'react'
import { LosseLayout, useLocation, useLosseLayout } from '@ubo/losse-sjedel'
import Footer from './Footer'
import Header from './Header/Header'
import Popup from './Popup'

type AppContext = {
  popupSubject: string
  setPopupSubject: (subject: string) => void
}

export const AppContext = createContext<AppContext>({
  popupSubject: '',
  setPopupSubject: () => null
})

export const useAppContext = () => {
  const context = useContext(AppContext)

  if (context === undefined) throw new Error('useAppContext must be used within a AppContextProvider')

  return context as AppContext
}

export default function Layout({ children }: PropsWithChildren<{}>) {
  const [popupSubject, setPopupSubject] = useState('')

  return (
    <AppContext.Provider value={{ popupSubject, setPopupSubject }}>
      <LosseLayout>
        <Content>{children}</Content>
      </LosseLayout>
    </AppContext.Provider>
  )
}

function Content({ children }: React.PropsWithChildren<{}>) {
  const { isScrollable, setScrollable } = useLosseLayout()
  const location = useLocation()

  useEffect(() => {
    if (isScrollable) document.body.style.overflow = 'auto'
    else document.body.style.overflow = 'hidden'
  }, [isScrollable])

  useEffect(() => {
    if (!isScrollable) setScrollable(true)
  }, [location.pathname])

  return (
    <>
      <Header />
      <main className="min-h-screen-dynamic pt-[75px] sm:pt-[100px] lg:pt-[125px]">{children}</main>
      <Footer />
      <Popup />
    </>
  )
}
