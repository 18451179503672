import { LosseLink, LossePlaatjie, losseContentParse } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import ContentFields from '~/components/elements/ContentFields'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentBlocks({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentBlocks" className="section">
      <div className="container">
        <div className="bg-white box--rounded box--shadow lg:p-10 xl:px-28 xl:py-20 p-6 md:p-8 overflow-hidden">
          <div className="max-sm:px-4">
            <ContentFields fields={fields} />
          </div>

          <div className="space--top grid md:grid-cols-3 gap-6 xl:gap-16 rounded-l-xl">
            {fields.blocks.map((block, index) => (
              <LosseLink
                key={index}
                to={block?.link?.url}
                target={block?.link?.target}
                className="bg-ftf-seashell rounded-xl p-6 xl:p-8 max-xs:min-w-[75%] max-md:min-w-[65%] group hover:bg-ftf-mine-shafter"
              >
                <div>
                  <LossePlaatjie src={block?.icon} className="h-10 w-10 lg:h-11 lg:w-11 mb-3 object-contain" maxwidth={150} />
                  <h4 className="font-black text-ftf-mine-shaft text-xl xl:text-2xl mb-2 md:mb-3 group-hover:text-white">
                    {losseContentParse(block?.link?.title)}
                  </h4>
                </div>
                <Content className="content children-p:text-ftf-mine-shaft group-hover:children-p:text-white/75">
                  {block?.description}
                </Content>
              </LosseLink>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
