import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import type { Branche as Props } from '~/graphql/types'

export default function Post({ data }: { data: Props }) {
  if (!data.title) return null

  return (
    <LosseLink
      to={data.uri}
      className="bg-white group sm:hover:bg-ftf-punch transition-colors block py-5 lg:py-7 px-4 lg:px-6 rounded-xl lg:rounded-3xl"
    >
      <LossePlaatjie
        src={data?.recap?.icon}
        title={data.title}
        className="w-10 h-10 object-contain opacity-50 group-hover:opacity-100"
        maxwidth={700}
      />
      <h4 className="max-lg:h-[40px] max-xl:h-[55px] line-clamp-2 lg:text-lg leading-4 group-hover:text-white text-ftf-mine-shaft mt-4 font-bold">
        {data.title}
      </h4>
    </LosseLink>
  )
}
