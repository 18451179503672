import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import ContentFields from '~/components/elements/ContentFields'
import Cylinder from '~/shapes/Cylinder'
import Flow from '~/components/elements/Flow'

export default function BannerWithFlow({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerWithFlow" className="relative overflow-hidden section--bottom">
      <div className="container 2xl:max-w-screen-2xl section--bottom space--top relative z-10">
        <Breadcrumbs />
        <div className="grid sm:grid-cols-2 gap-10 lg:gap-20 section--top max-w-screen-xl mx-auto">
          <div className="lg:pr-20 xl:pr-32 order-2">
            <Flow key={JSON.stringify(fields?.flow)} fields={fields} itemClassName="max-w-[200px] sm:max-w-[250px]" />
          </div>
          <div className="max-sm:order-1 order-3">
            <div className="text-white lg:max-w-lg">
              <ContentFields fields={fields} type="banner-notable" />
            </div>
          </div>
        </div>
      </div>
      {/* 0,441805225653207 aspect ratio */}
      <Cylinder className="text-ftf-punch absolute max-sm:opacity-25 max-2xl:opacity-50 top-[25px] lg:top-[100px] max-lg:-right-[400px] lg:-left-[600px] w-[500px] h-[221px] lg:w-[700px] lg:h-[309px]" />
    </section>
  )
}
