import ContentFields from '~/components/elements/ContentFields'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import Hyperjump from '~/shapes/Hyperjump'

export default function ContentLightBgAndStripes({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentLightBgAndStripes" className="section bg-white relative overflow-hidden">
      <div className="container py-6 sm:py-10 lg:py-16">
        <div className="text-ftf-mine-shafter max-w-2xl mx-auto">
          <ContentFields fields={fields} type="alternative" />
        </div>
      </div>
      <Hyperjump className="absolute z-0 top-1/2 -translate-y-1/2 right-0 flip--horizontal transform opacity-70 max-lg:w-full max-lg:h-full" />
    </section>
  )
}
