import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Detail } from '~/graphql/types'

export default function DetailTextAndImage({ fields }: { fields: Page_Flexcontent_Flex_Detail }) {
  return (
    <section data-component="DetailTextAndImage" className="section--detail bg-white" id={fields?.sectionId}>
      <div className="container xl:max-w-screen-xl">
        <div className="grid md:grid-cols-2 gap-6 sm:gap-10 lg:gap-20">
          <div
            className={clsx(
              fields?.imagePosition === 'left' ? 'md:order-1' : 'md:order-3',
              'order-1 flex items-center max-md:justify-center'
            )}
          >
            <LossePlaatjie src={fields?.image} className="detail--image" maxwidth={700} />
          </div>
          <div className="order-2 flex items-center">
            <Content className="content">{fields?.description}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
