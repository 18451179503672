import { type LoaderData, useLosseLayout, useLoaderData } from '@ubo/losse-sjedel'
import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Component_Header } from '~/graphql/types'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'
import Logo from '~/components/elements/Logo'

export interface HeaderLoaderData extends Omit<LoaderData, 'header'> {
  header: Component_Header
}

export default function Header() {
  const { isSticky } = useLosseLayout()
  const { header } = useLoaderData<HeaderLoaderData>()

  return (
    <nav>
      <div
        data-component="Header"
        className={clsx(isSticky ? 'pt-4' : 'h-[100px] sm:h-[125px] pt-4', 'fixed left-0 top-0 right-0 z-50 transition-all')}
      >
        <div className="container max-w-screen-2xl">
          <div
            className={clsx(
              'flex h-full items-center justify-between py-2 lg:py-3 pr-3 lg:pr-4 rounded-full transition-all',
              isSticky && 'bg-white box--shadow pl-5 lg:pl-6',
              !isSticky && ''
            )}
          >
            <LosseLink to="/" aria-label="Home">
              <Logo className={clsx(isSticky ? 'text-ftf-mine-shaft' : 'text-white', 'lg:w-24 w-[70px]')} />
            </LosseLink>

            <div className="flex items-center 2xl:gap-14 xl:gap-10 gap-2">
              <div className={clsx('bg-white rounded-full order-2', !isSticky && 'box--shadow')}>
                <HeaderDesktop />
                <HeaderMobile />
              </div>

              <div className="flex items-center xl:gap-3 gap-2 xl:order-3 order-1 max-sm:hidden">
                {header.menuCta.map((item) => (
                  <LosseLink
                    key={item.link.url}
                    className={clsx(
                      item.color === 'green' && 'btn--la-palma',
                      item.color === 'red' && 'btn--punch',
                      !isSticky && 'box--shadow',
                      'xl:text-base text-sm max-sm:py-2 max-sm:px-2 py-[10px] max-sm:text-s'
                    )}
                    src={item.link}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute left-0 top-0 w-full h-[50vh] bg-gradient-to-b from-ftf-mine-shafter to-transparent pointer-events-none" />
    </nav>
  )
}
