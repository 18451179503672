import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import ContentFields from '~/components/elements/ContentFields'
import Cylinder from '~/shapes/Cylinder'

export default function BannerDefault({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerDefault" className="relative overflow-hidden section--bottom">
      <div className="container 2xl:max-w-screen-2xl section--bottom space--top relative z-10">
        <Breadcrumbs />
        <div className="grid sm:grid-cols-2 gap-10 md:gap-20">
          <div className="order-2">
            <div className="sm:mt-10 md:mt-16 lg:mt-24 xl:mt-32 lg:pl-16 text-white">
              <ContentFields fields={fields} type={fields?.icon ? 'banner' : 'banner-notable'} />
            </div>
          </div>
          <div className="max-sm:order-1 sm:order-3 max-sm:mt-10">
            <div className="relative">
              {fields?.icon && (
                <div className="absolute bg-ftf-punch z-10 rounded-full aspect-square h-[22%] flex--center left-[30%] top-[10%]">
                  <LossePlaatjie
                    src={fields?.icon}
                    className="w-[50%] h-[50%] object-contain opacity-75"
                    maxwidth={300}
                    style={{ filter: 'brightness(0) invert(1)' }}
                  />
                </div>
              )}
              <LossePlaatjie
                loading="eager"
                className="w-full h-full max-sm:max-w-[300px] max-sm:mx-auto max-h-[600px] lg:-mt-16 object-contain relative z-0"
                src={fields.image}
                maxwidth={1000}
              />
            </div>
          </div>
        </div>
      </div>
      <Cylinder className="text-ftf-punch absolute lg:-right-[40rem] sm:-right-[37rem] -right-[15rem] max-sm:top-3 sm:bottom-10 z-0 h-[133px] w-[300px] sm:h-[221px] sm:w-[500] lg:w-[842px] lg:h-[372px]" />
    </section>
  )
}
