import clsx from 'clsx'

export default function Cylinder({ className = undefined }: { className?: string }) {
  return (
    <svg
      width="842"
      height="372"
      viewBox="0 0 842 372"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('pointer-events-none', className)}
    >
      <path
        d="M839.608 185.975C839.608 287.763 757.093 370.277 655.305 370.277L186.644 370.277C84.8555 370.277 2.34107 287.763 2.34108 185.975C2.34108 84.1862 84.8555 1.67181 186.644 1.6718L655.305 1.67177C757.093 1.67177 839.608 84.1862 839.608 185.975Z"
        stroke="currentColor"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
