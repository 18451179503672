import clsx from 'clsx'

export default function CylinderTilted({ className = undefined }: { className?: string }) {
  return (
    <svg
      width="704"
      height="704"
      viewBox="0 0 704 704"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('pointer-events-none', className)}
    >
      <path
        d="M55.9815 55.9815C127.957 -15.9938 244.65 -15.9938 316.625 55.9815L648.019 387.375C719.994 459.35 719.994 576.043 648.019 648.018C576.043 719.994 459.35 719.994 387.375 648.018L55.9815 316.625C-15.9938 244.65 -15.9938 127.957 55.9815 55.9815Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
