import useInfo from '~/utils/useInfo'

export default function Socials() {
  const info = useInfo()

  return (
    <>
      {(info.linkedinUrl || info.facebookUrl) && (
        <div className="flex items-center">
          {info.linkedinUrl && (
            <a
              href={info.linkedinUrl}
              target="_blank"
              rel="noreferrer"
              className="text-white/50 w-10 h-10 rounded-full flex--center hover:bg-ftf-punch hover:text-white"
            >
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5">
                <path d="M4.91992 6.875H0V22H4.91992V6.875Z" fill="currentColor" />
                <path
                  d="M18.3203 7.05225C18.2682 7.03613 18.2182 7.01787 18.1634 7.00283C18.0974 6.98779 18.0313 6.97544 17.9642 6.96416C17.7031 6.91206 17.4168 6.875 17.0812 6.875C14.213 6.875 12.3938 8.96113 11.7944 9.7668V6.875H6.875V22H11.7949V13.75C11.7949 13.75 15.5128 8.57173 17.0817 12.375V22H22.0005V11.7933C22.0005 9.50791 20.4343 7.60386 18.3208 7.05225H18.3203Z"
                  fill="currentColor"
                />
                <path
                  d="M4.8125 2.40625C4.8125 3.73506 3.73506 4.8125 2.40625 4.8125C1.07744 4.8125 0 3.73506 0 2.40625C0 1.07744 1.07744 0 2.40625 0C3.73506 0 4.8125 1.07744 4.8125 2.40625Z"
                  fill="currentColor"
                />
              </svg>
              <span className="sr-only">LinkedIn FTF finance</span>
            </a>
          )}
          {info.facebookUrl && (
            <a
              href={info.facebookUrl}
              target="_blank"
              rel="noreferrer"
              className="text-white/50 w-10 h-10 rounded-full flex--center hover:bg-ftf-punch hover:text-white"
            >
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5">
                <path
                  d="M12.7038 22V11.9655H16.0706L16.5757 8.05372H12.7038V5.55662C12.7038 4.42442 13.0169 3.65284 14.6423 3.65284L16.712 3.65199V0.153153C16.3541 0.10664 15.1255 0 13.6955 0C10.7094 0 8.66511 1.82266 8.66511 5.1692V8.05372H5.28809V11.9655H8.66511V22H12.7038Z"
                  fill="currentColor"
                />
              </svg>
              <span className="sr-only">Facebook FTF finance</span>
            </a>
          )}
        </div>
      )}
    </>
  )
}
