/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { LosseLink, useLoaderData, useLocation, useLosseLayout } from '@ubo/losse-sjedel'
import HeaderMenu from './HeaderMenu'
import { motion, AnimatePresence } from 'framer-motion'
import { type HeaderLoaderData } from './Header'
import clsx from 'clsx'
import Logo from '~/components/elements/Logo'

export default function HeaderMobile() {
  const { setScrollable } = useLosseLayout()
  const location = useLocation()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { header } = useLoaderData<HeaderLoaderData>()

  useEffect(() => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false)
    }
  }, [location.pathname, location.hash, location.search])

  return (
    <div className="block xl:hidden">
      <button
        type="button"
        onClick={() => {
          setMobileMenuOpen(!mobileMenuOpen)
          setScrollable(false)
        }}
        className="flex items-center gap-2 sm:gap-4 h-10 px-4 sm:px-5 rounded-full hover:bg-ftf-seashell group overflow-hidden"
        title="Open menu"
      >
        <div className="uppercase font-medium text-sm group-hover:text-ftf-mine-shafter">Menu</div>
        <div className="z-10 h-full py-2 w-8 flex flex-col gap-[6px] justify-center">
          <div className="mx-auto h-[2px] w-full bg-ftf-mine-shaft group-hover:bg-ftftext-ftf-mine-shafter" />
          <div className="mx-auto h-[2px] w-full bg-ftf-mine-shaft group-hover:bg-ftftext-ftf-mine-shafter" />
          <div className="mx-auto h-[2px] w-full bg-ftf-mine-shaft group-hover:bg-ftftext-ftf-mine-shafter" />
        </div>
      </button>
      <AnimatePresence>
        {mobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: -20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: -20 }}
            className="fixed left-0 top-0 z-50 h-full w-full bg-ftf-mine-shaft"
          >
            <div
              className="absolute right-7 top-7 text-center"
              role="button"
              onClick={() => {
                setMobileMenuOpen(!mobileMenuOpen)
                setScrollable(true)
              }}
              aira-label="Close menu"
            >
              <div className="relative ml-4 h-8 w-8 before:absolute before:h-[25px] before:w-[2px] before:rotate-45 before:bg-white before:[content:''] after:absolute after:h-[25px] after:w-[2px] after:-rotate-45 after:bg-white after:[content:'']" />
            </div>
            <div className="px-6 pt-6 pb-20 w-full h-full flex flex-col justify-between">
              <div>
                <Logo className="text-ftf-mine-shaft" />

                <div className="px-2">
                  <ul className="m-0 pl-0 mt-16 flex flex-col gap-1">
                    <HeaderMenu setMobileMenuOpen={setMobileMenuOpen} />
                  </ul>
                </div>
              </div>

              <div className="px-2">
                <div className="flex flex-col items-center gap-3 mt-10">
                  {header.menuCta.map((item) => (
                    <LosseLink
                      key={item.link.url}
                      className={clsx(
                        item.color === 'green' && 'btn--la-palma',
                        item.color === 'red' && 'btn--punch',
                        'text-lg min-w-[250px] justify-center'
                      )}
                      src={item.link}
                    />
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
