/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import Modal from '../elements/Modal'
import { useLoaderData, useLocation, useLosseData, useLosseLayout } from '@ubo/losse-sjedel'
import type { Component_Popup } from '~/graphql/types'
import Loading from '../elements/Loading'
import Content from '../elements/Content'
import Form from '../elements/Form'
import { useAppContext } from './Layout'

export default function Popup() {
  const appContext = useAppContext()
  const { page } = useLoaderData()
  const { setScrollable } = useLosseLayout()
  const [isOpen, setOpen] = useState(false)
  const [isSend, setSend] = useState(false)
  const location = useLocation()
  const [response, state] = useLosseData<{ data: Component_Popup }>(
    'Popup',
    {
      id: 100
    },
    !!isOpen
  )

  useEffect(() => {
    function handleClick(event: MouseEvent) {
      const scrollY = window.scrollY

      if (event.target instanceof HTMLElement && event.target.tagName === 'A') {
        const href = event.target.getAttribute('href')

        if (href.includes('#adviesgesprek')) {
          setScrollable(false)

          setTimeout(() => {
            window.scrollTo({ top: scrollY, behavior: 'smooth' })
          }, 100)
        }
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  useEffect(() => {
    const _isOpen = location.hash === '#adviesgesprek'

    if (!isOpen && _isOpen) setOpen(true)
    if (isOpen && !_isOpen) setOpen(false)
  }, [location.hash])

  useEffect(() => {
    if (isOpen) return

    window.location.hash = ''

    if (isSend) setSend(false)
  }, [isOpen])

  const { data } = response || {}

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setOpen(false)

        const scrollY = window.scrollY

        setTimeout(() => {
          window.scrollTo({ top: scrollY, behavior: 'smooth' })
        }, 100)
      }}
    >
      {state === 'loading' && (
        <div className="flex justify-center py-20">
          <Loading className="text-ftf-punch" />
        </div>
      )}
      {state === 'done' && (
        <div>
          {!isSend && (
            <>
              <h2 className="title lg:text-4xl text-ftf-mine-shaft mb-2">{data?.title}</h2>
              <h3 className="sub--title text-ftf-punch font-bold mb-6">{data?.subtitle}</h3>
              <Content className="content children:text-ftf-mine-shaft/50 children:leading-3 text-sm mb-10">{data?.description}</Content>
            </>
          )}
          <div className="-m-4 p-4 relative">
            {appContext.popupSubject && (
              <div className="text-ftf-mine-shafter/50 font-bold text-base py-4 text-center">{appContext.popupSubject}</div>
            )}
            <Form
              data={data?.form}
              defaultFields={{ input_6: appContext.popupSubject, input_7: page?.title }}
              onSubmitDone={() => {
                setSend(true)
              }}
            />
          </div>
        </div>
      )}
    </Modal>
  )
}
