import { LosseBlogBink, type LosseBlogBinkReadableParams, useLosseBlogBink } from '@ubo/losse-sjedel'
import type { ContentNode, Maybe, Page_Flexcontent_Flex_Posts, Post as PostProps, WpPageInfo } from '~/graphql/types'
import Post from './post-types/Post'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import clsx from 'clsx'
import PostHighlighted from './post-types/PostHighlighted'
import Case from './post-types/Case'
import Vacature from './post-types/Vacature'

export const READABLE_PARAMS: LosseBlogBinkReadableParams = {
  filters: 'onderwerp',
  direction: 'volgorde',
  sort: 'sortering',
  cursor: 'pagina',
  query: 'zoekterm'
}

export default function PostsBlog({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  if (!fields.blog?.pageInfo) {
    return null
  }

  return (
    <section data-component="PostsBlog" className="relative z-10">
      <LosseBlogBink pageInfo={fields.blog?.pageInfo as Maybe<WpPageInfo> | undefined} readableParams={READABLE_PARAMS}>
        <Overview fields={fields} />
      </LosseBlogBink>
    </section>
  )
}

type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
  }
}

const getPostTypeName = (fields: Page_Flexcontent_Flex_Posts) => {
  const items = fields.blog?.edges as Edge[]
  return items?.[0]?.node?.__typename
}

const PostTypes: { [key: string]: any } = {
  Post: Post,
  Case: Case,
  Vacature: Vacature
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const { isFilterActive, toggleFilter, hasNextPage, hasPrevPage, nextPage, prevPage } = useLosseBlogBink()
  const postTypeName = getPostTypeName(fields)

  return (
    <>
      <div className="container space--top 2xl:max-w-screen-2xl">
        <Breadcrumbs />

        <div className="section--top mb-10 md:mb-28 max-w-[1160px] mx-auto lg:flex items-center justify-between gap-10">
          <div className="flex items-end gap-4 lg:gap-10">
            <h1 className="title text-white">{fields?.title}</h1>
            <div className="text-white font-light max-lg:text-sm relative bottom-[5px] sm:bottom-[2px] lowercase">
              {fields?.blog?.pageInfo?.total} {fields?.title}
            </div>
          </div>
          <div className="md:flex items-center flex-wrap text-white max-lg:mt-6 relative top-2 max-lg:max-w-full max-xl:max-w-[70%]">
            <div className="max-lg:text-sm font-light max-xl:mb-2">Filter op onderwerp</div>
            <div className="max-md:mt-2 xl:ml-10 flex items-center flex-wrap gap-2 lg:max-w-[650px]">
              {fields?.filters?.map((filter) => {
                const isActive = isFilterActive(filter?.name)

                return (
                  <button
                    key={filter?.databaseId}
                    type="button"
                    onClick={() => {
                      toggleFilter(filter?.name)
                    }}
                    className={clsx('tag tag--button flex items-center gap-2', isActive && '!pl-3')}
                  >
                    {isActive && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-4 h-4 text-ftf-la-palma"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    )}
                    {filter?.name}
                  </button>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white section--bottom max-md:pt-10">
        <div className="container">
          <div className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 gap-x-4 gap-y-10 lg:gap-y-14 relative md:-top-16">
            {!hasPrevPage && postTypeName !== 'Vacature' && (
              <div className="xs:col-span-2 sm:col-span-3 max-sm:hidden">
                <PostHighlighted data={fields.blog?.edges?.[0]?.node as PostProps} />
              </div>
            )}
            {fields.blog?.edges?.map((edge) => {
              if (!edge || !edge.node) return null

              const Component = PostTypes[postTypeName] || PostTypes.Post

              return (
                <div key={edge.node.uri}>
                  <Component data={edge.node} type="blog" />
                </div>
              )
            })}
          </div>

          <div className="flex--center gap-2 md:gap-4 max-md:mt-10">
            <button
              type="button"
              onClick={() => {
                prevPage()
              }}
              disabled={!hasPrevPage}
              className="btn--mine-shaft--outline h-8 w-8 sm:w-10 sm:h-10 lg:w-12 lg:h-12 flex--center text-ftf-mine-shaft p-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-6 h-6"
              >
                <polyline points="15 18 9 12 15 6"></polyline>
              </svg>
            </button>
            <button
              type="button"
              onClick={() => {
                nextPage()
              }}
              disabled={!hasNextPage}
              className="btn--mine-shaft--outline h-8 w-8 sm:w-10 sm:h-10 lg:w-12 lg:h-12 flex--center text-ftf-mine-shaft p-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-6 h-6"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
