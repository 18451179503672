import { LossePlaatjie } from '@ubo/losse-sjedel'
import ContentFields from '~/components/elements/ContentFields'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentImageRightDarkBg({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentImageRightDarkBg" className="section bg-gradient-to-b from-ftf-mine-shaft to-ftf-mine-shafter">
      <div className="container">
        <div className="grid sm:grid-cols-2 gap-4 sm:gap-10 lg:px-10 xl:px-20 p-4 sm:p-8">
          <div className="flex items-center order-2">
            <div className="text-white">
              <ContentFields fields={fields} type="notable" />
            </div>
          </div>
          <div className="relative sm:order-3 order-1">
            <LossePlaatjie
              src={fields?.image}
              className="max-sm:max-w-[250px] w-[75%] sm:w-[85%] md:w-[80%] lg:w-[70%] h-full object-contain relative z-10 max-sm:mx-auto max-md:ml-auto"
              width={700}
              alt={fields?.title}
            />

            <div className="absolute bottom-0 -left-20 z-0 pointer-events-none max-sm:hidden">
              <svg
                width="702"
                height="702"
                viewBox="0 0 702 702"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-[250px] h-[250px] sm:w-[350px] sm:h-[350px] lg:w-[450px] lg:h-[450px] xl:w-[550px] xl:h-[550px]"
              >
                <path
                  d="M647.019 54.9815C575.043 -16.9938 458.35 -16.9938 386.375 54.9815L54.9815 386.375C-16.9938 458.35 -16.9938 575.043 54.9815 647.018C126.957 718.994 243.65 718.994 315.625 647.018L647.019 315.625C718.994 243.65 718.994 126.957 647.019 54.9815Z"
                  stroke="#DE362F"
                  strokeWidth="0.5"
                  strokeMiterlimit="10"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
