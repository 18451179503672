import clsx from 'clsx'

export default function CylinderAndCircle({ className = undefined }: { className?: string }) {
  return (
    <svg
      width="563"
      height="995"
      viewBox="0 0 563 995"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('pointer-events-none', className)}
    >
      <path
        d="M43.0399 519.96C100.426 577.347 193.469 577.347 250.855 519.96L519.96 250.855C577.347 193.469 577.347 100.426 519.96 43.0399C462.574 -14.3466 369.531 -14.3466 312.145 43.0399L43.0399 312.145C-14.3466 369.531 -14.3466 462.574 43.0399 519.96Z"
        fill="#DE362F"
      />
      <path
        d="M385.945 744.055C328.538 686.648 235.462 686.648 178.055 744.055C120.648 801.462 120.648 894.538 178.055 951.945C235.462 1009.35 328.538 1009.35 385.945 951.945C443.352 894.538 443.352 801.462 385.945 744.055Z"
        fill="#DE362F"
      />
    </svg>
  )
}
