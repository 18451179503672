import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import PostsLatest from './PostsLatest'
import PostsBlog from './PostsBlog'
import PostCaseHighlighted from './PostCaseHighlighted'
import PostsBranches from './PostsBranches'
import PostsEmployees from './PostsEmployees'
import PostsRelated from './PostsRelated'
import PostsOverview from './PostsOverview'

export default function PostsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    latest: PostsLatest,
    blog: PostsBlog,
    case_highlighted: PostCaseHighlighted,
    branches: PostsBranches,
    employees: PostsEmployees,
    related: PostsRelated,
    overview: PostsOverview
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
