import { LosseLink } from '@ubo/losse-sjedel'
import type { Branche, Page_Flexcontent_Flex_Posts } from '~/graphql/types'

export default function PostsOverview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section data-component="PostsOverview" className="bg-ftf-dove-gray section--bottom">
      <div className="container relative lg:max-w-screen-lg z-10">
        <div className="grid gap-4 sm:gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {fields?.allPosts?.nodes?.map((_node) => {
            const node = _node as Branche

            return (
              <LosseLink
                key={node?.databaseId}
                to={node?.uri}
                className="text-white text-lg sm:text-xl bg-ftf-mine-shaft rounded-2xl py-6 px-8 hover:bg-ftf-punch focus:scale-95 transition-all flex items-center justify-center text-center"
              >
                {node?.title}
              </LosseLink>
            )
          })}
        </div>
      </div>
    </section>
  )
}
